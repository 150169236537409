import { useCallback, useEffect, useRef } from 'react';

export const useInterval = (fn, ms) => {
  const intervalRef = useRef();

  const run = useCallback(async () => {
    intervalRef.current = setInterval(await fn, [ms]);
  }, [fn]);

  const stop = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    run();
    return (() => {
      stop();
    });
  }, [run]);
};
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useUser } from 'store/user';
import { NativeSelect, FormControl, InputBase } from '@material-ui/core';
import {
  Button,
  GroupCard
} from 'components';
import banner from 'assets/images/dashboard.png';
import { useSchoolYear } from 'utils/hooks/useSchoolYear';
import { UiflexBox } from 'styles';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { ModalWelcome } from './ModalWelcome/ModalWelcome';
import {
  UiMyOrganizations,
  UiBanner,
  UiCardBox,
} from './MyOrganizations.style';

const ALL_SCHOOL_YEAR = 'all';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme)=> ({
	root: {
		backgroundColor: '#fff',
		zIndex: 2,
	},

	margin: {
		margin: theme.spacing(1),
		width: '120px',
	},
}));

/**
 * 我的組織列表
 */

export const MyOrganizations = () => {
  const history = useHistory();
  const classes = useStyles();
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const ref = useRef(null);
  const [
    { myOrganization }
  ] = useUser();
  const { schoolYearOptionsWithAll: schoolYearOptions } = useSchoolYear();
  const [{
    year,
  }, setState] = useSetState({
    year: ALL_SCHOOL_YEAR,
  });
  const [isOpenModal, setIsOpenModal] = useState(true);

  const onFilterChange = key => event => setState({ [key]: event.target.value });

  /* 關閉彈窗 */
  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <UiMyOrganizations>
      <ModalWelcome
        open={isOpenModal}
        onClose={closeModal}
      />

      <UiBanner>
        <img src={banner} alt="live" />
      </UiBanner>
      <div className="actionBox">
        <div className="title">我的班級</div>
        <UiflexBox>
          <FormControl className={classes.margin}>
            <NativeSelect
              id="demo-customized-select-native"
              value={year}
              label="學年度"
              placeholder="篩選學年度"
              onChange={onFilterChange('year')}
              input={<BootstrapInput />}
            >
              {schoolYearOptions.map((item,index)=>{
                return (
                  <option key={index} value={item.value}>{item.name}</option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <Button
            iconSvg='Bullhorn'
            onClick={()=>history.push('/home/announcement')}
          >公告
          </Button>
        </UiflexBox>
      </div>
      <UiCardBox>
        <GroupCard
          organizationId={organizationId}
          year={year}
          role={myOrganization.role}
        />
      </UiCardBox>
      <div ref={ref} style={{ width: '100%', height: '20px' }}></div>
    </UiMyOrganizations>
  );
};



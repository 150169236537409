import React from 'react';
import { Grid } from  '@material-ui/core';
import {
  UiTitleWrapper,
  UiTitle,
  UiSubtitle,
  UiServicesWrapper,
  UiService,
  UiServiceFigure,
  UiServiceTitle,
  UiServiceSubtitle,
} from './SessionService.style';
import { Icon } from 'components';

const TITLE = '加值服務';
const SUBTITLE = '可前往「班級」>「學習全資源」查看更多內容';
const SERVICES = [
  {
    image: 'Study',
    title: 'OneStudy 線上學院',
    subtitle: '隨時隨地自學影片及測驗',
    link: 'https://onestudy.oneclass.com.tw/MainPage',
  },
  {
    image: 'BookReader',
    title: 'OneBook 智慧電子書',
    subtitle: '體驗豐富電子教科書資源',
    link: 'https://reader.oneclass.com.tw/bookstore',
  },
];


export const SessionService = () => {
  return (
    <>
      <UiTitleWrapper>
        <UiTitle>{TITLE}</UiTitle>
        <UiSubtitle>{SUBTITLE}</UiSubtitle>
      </UiTitleWrapper>
      <UiServicesWrapper>
        {
          SERVICES.map(({ image, title, subtitle, link }) => 
            <UiService container key={title} onClick={() => window.open(link, '_blank')}>
              <Grid item justifyContent='center'>
                <UiServiceFigure>
                  <Icon.Svg name={image} size='30px' color='#FFF' />
                </UiServiceFigure>
              </Grid>
              <Grid item>
                <Grid item>
                  <UiServiceTitle>
                    {title}
                  </UiServiceTitle>
                </Grid>
                <Grid item>
                  <UiServiceSubtitle>
                    {subtitle}
                  </UiServiceSubtitle>
                </Grid>
              </Grid>
            </UiService>
          )
        }
      </UiServicesWrapper>
    </>
  );
};

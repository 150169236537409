/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { Button } from 'components';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { UiRoot } from './ActionOther.style';
import { ModalLeaveStudent } from '../ModalLeaveStudent/ModalLeaveStudent';
import { ModalLeaveTeacher } from '../ModalLeaveTeacher/ModalLeaveTeacher';
import { ModalResource } from '../ModalResource/ModalResource';
import {
  COURSE_TYPE,
  ISSUE_GROUP_TEXT,
  LIVE_COURSE_STATUS,
  LIVE_REQUEST_ISSUES,
} from '../HomeSessionsTableNew.constant';

const HIDE_TAKE_LEAVE_ISSUES = [
  'studentTakeLeave',
  'studentTakeLeaveTooLate',
  'studentAskTransfer',
  'studentAskSubstitute',
  'studentAskCancel',
];

const DISPLAY_TEACHER_TAKE_LEAVE_ISSUES = [
  'teacherTakeLeave',
  'teacherTakeLeaveTooLate',
];

export const ActionOther = ({ course, fetchCourse, liveCourseStatus, table }) => {
  const { id: sessionId, type: courseType, issueGroup, isAudition } = course;
  const history = useHistory();
  const { classId } = useParams();
  const [isOpenModal, setIsOpenModal] = useState({
    resource: false,
    studentTakeLeave: false,
    teacherTakeLeave: false,
  });
  
  const issueGroupText = issueGroup
    .filter(issue => LIVE_REQUEST_ISSUES.includes(issue))
    .map(issue => ISSUE_GROUP_TEXT[issue])
    .join('、');
  
  /* 隱藏我要請假、教師請假處理按鈕 boolean */
  const isHideTakeLeaveBtn = courseType === COURSE_TYPE.GROUP_LIVE_COURSE
  || issueGroup.some(issue => HIDE_TAKE_LEAVE_ISSUES.includes(issue));
  
  /* 顯示 教師請假處理 按鈕 boolean */
  const isDisplayTeacherTakeLeaveBtn = issueGroup.some(issue => DISPLAY_TEACHER_TAKE_LEAVE_ISSUES.includes(issue));

  /* click 課堂報告 */
  const goToSessionReport = () => () => {
    // const location = {
    //   pathname: `/home/${classId}/sessions/${id}/timespan/${timeSpanId}/report`,
    //   state: { resourceId: resources[0], nowPage }
    // };
    // history.push(location);
  };
  const goToSessionFeedback = () => () => {
    const location = {
      pathname: `/home/${classId}/sessions/${sessionId}/${isAudition ? 'sessionfeedbackaudition' : 'sessionfeedback'}`,
      state: { course, table },
    };
    history.push(location);
  };
  const toggleModal = (key, bool) => () => setIsOpenModal(prev => ({ ...prev, [key]: bool }));

  const btn = {
    resource: { text: '課程資源', color: 'new', onClick: toggleModal('resource', true) },
    sessionReport: { text: '課堂報告', color: 'visibility', onClick: goToSessionReport(), },
    feedback: { text: '課後回饋', color: 'visibility', onClick: goToSessionFeedback() },
    studentTakeLeave: { text: '我要請假', color: 'standardSecondary', onClick: toggleModal('studentTakeLeave', true) },
    teacherTakeLeave: { text: '教師請假處理', color: 'normal', onClick: toggleModal('teacherTakeLeave', true) },
  };

  return (
    <UiRoot>
      {/* 課程資源 彈窗 */}
      {isOpenModal.resource &&
        <ModalResource
          open={isOpenModal.resource}
          close={toggleModal('resource', false)}
          course={course}
        />}

      {/* 處理學生請假 彈窗 */}
      {isOpenModal.studentTakeLeave &&
        <ModalLeaveStudent
          open={isOpenModal.studentTakeLeave}
          close={toggleModal('studentTakeLeave', false)}
          course={course}
          fetchCourse={fetchCourse}
        />}

      {/* 處理老師請假 彈窗 */}
      {isOpenModal.teacherTakeLeave &&
        <ModalLeaveTeacher
          open={isOpenModal.teacherTakeLeave}
          close={toggleModal('teacherTakeLeave', false)}
          course={course}
          fetchCourse={fetchCourse}
        />}

      {/* 準備中、進行中 */}
      {(liveCourseStatus === LIVE_COURSE_STATUS.PREPARING
        || liveCourseStatus === LIVE_COURSE_STATUS.PREPARING_AGO_30
        || liveCourseStatus === LIVE_COURSE_STATUS.PROCESSING) &&
        <>
          <Button
            disabled={btn.resource.disabled}
            buttonColor={btn.resource.color}
            onClick={btn.resource.onClick}
          >
            {btn.resource.text}
          </Button>
          {/* 20230309 - 暫時隱藏學生請假按鈕 */}
          {/* {isHideTakeLeaveBtn
            ? <></>
            : // 學生請假
              <Button
                disabled={btn.studentTakeLeave.disabled}
                buttonColor={btn.studentTakeLeave.color}
                onClick={btn.studentTakeLeave.onClick}
              >
                {btn.studentTakeLeave.text}
              </Button>} */}
        </>}

      {/* 已結束 */}
      {(liveCourseStatus === LIVE_COURSE_STATUS.OVER
        || liveCourseStatus === LIVE_COURSE_STATUS.OVER_WITHIN_90) &&
        <>
          <Button
            disabled={btn.resource.disabled}
            buttonColor={btn.resource.color}
            onClick={btn.resource.onClick}
          >
            {btn.resource.text}
          </Button>
          {/* 課堂報告 */}
          {/* <Button
            disabled={btn.sessionReport.disabled}
            buttonColor={btn.sessionReport.color}
            onClick={btn.sessionReport.onClick}
          >
            {btn.sessionReport.text}
          </Button> */}
        </>}


      {/* 已完成 */}
      {(liveCourseStatus === LIVE_COURSE_STATUS.FINISH
        || liveCourseStatus === LIVE_COURSE_STATUS.FINISH_WITHIN_90) &&
        <>
          <Button
            disabled={btn.resource.disabled}
            buttonColor={btn.resource.color}
            onClick={btn.resource.onClick}
          >
            {btn.resource.text}
          </Button>
          {/* 課堂報告 */}
          {/* <Button
            disabled={btn.sessionReport.disabled}
            buttonColor={btn.sessionReport.color}
            onClick={btn.sessionReport.onClick}
          >
            {btn.sessionReport.text}
          </Button> */}
          <Button
            disabled={btn.feedback.disabled}
            buttonColor={btn.feedback.color}
            onClick={btn.feedback.onClick}
          >
            {btn.feedback.text}
          </Button>
        </>}

      {/* 有異常 */}
      {liveCourseStatus === LIVE_COURSE_STATUS.ISSUE &&
        (isHideTakeLeaveBtn ? (
          <Typography>
            {issueGroupText}
          </Typography>
        ) : (
          // 教師請假處理
          isDisplayTeacherTakeLeaveBtn &&
            <Button
              buttonColor={btn.teacherTakeLeave.color}
              disabled={btn.teacherTakeLeave.disabled}
              onClick={btn.teacherTakeLeave.onClick}
            >
              {btn.teacherTakeLeave.text}
            </Button>
        ))}
    </UiRoot>
  );
};

ActionOther.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    intervalStatus: PropTypes.oneOf(['draft', 'publish', 'finish', 'cancel']),
    issue: PropTypes.oneOf(['none', 'other', 'teacherTakeLeave', 'studentTakeLeave', 'studentAskTransfer', 'studentAskCancel']),
    issueGroup: PropTypes.array,
    type: PropTypes.string,
  }),
  fetchCourse: PropTypes.func,
  forestageCourseStatus: PropTypes.string,
  liveCourseStatus: PropTypes.string,
  table: PropTypes.object,
};
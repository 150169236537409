import React from 'react';
import {
  UiBannerWrapper,
  UiBannerFigure,
  UiBannerImg,
  UiBannerFigcaption,
  UiBannerLink,
} from './HomeSessionsBanner.style';
import { Icon } from 'components';
import { useParams, useHistory, } from 'react-router-dom';
import { useClass } from 'store/class';
import theme from 'theme';

const publicClassLinkText = '前往課外學習';

export const HomeSessionsBanner = () => {
  const history = useHistory();
  const { classId } = useParams();
  const [{ myClasses }] = useClass();
  const { dataInfo: { userType } } = myClasses;

  return (
    userType === 'student' &&
      <UiBannerWrapper>
        <UiBannerFigure>
          <UiBannerImg onClick={()=>history.push(`/home/${classId}/opencourse`)} />
          <UiBannerFigcaption>
            <UiBannerLink to={`/home/${classId}/opencourse`}>
              <span>{publicClassLinkText}</span>
              <Icon
                icolor={theme.palette.primary.main}
                haveBg={false}
                name='chevronRight'
              />
            </UiBannerLink>
          </UiBannerFigcaption>
        </UiBannerFigure>
      </UiBannerWrapper>
  );
};
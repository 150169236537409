/* eslint-disable max-len */
import MatButton from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const UiButton = styled(MatButton)`
position: relative;
display: flex;
padding: ${({ buttonPadding }) => buttonPadding ? buttonPadding : '8px' };
min-width: ${({ minWidth }) => minWidth ? minWidth : '80px' };
min-height: 40px;
width: ${({ isFullWidth }) => isFullWidth && '100%' };
font-size: ${({ size })=> size ? size : '12px'};
color:
	${({ theme, textColor, buttoncolor}) => {
			if (textColor) return textColor;
			else {
				switch (buttoncolor) {
					case 'disable':
						return '#D5D7DE';
					case 'cancel':
						return '#3a4052';
					case 'standardSecondary':
						return '#121232';
					default:
						return theme.oneClass.button.text.default;
				}
			}
	}};

background:
	${(
		{ theme,
		buttoncolor,
		loading }
	) => loading === true ?
	theme.oneClass.button.background['disable'] :
	buttoncolor === 'danger' ?
	theme.palette.main[buttoncolor] :
	theme.palette.btn[buttoncolor]};
border: ${({buttoncolor}) => (buttoncolor === 'cancel' || buttoncolor === 'standardSecondary') ? '0.6px solid #D5D7DE' : 'none'};
outline: ${({buttoncolor}) => buttoncolor === 'none' && '1px solid #EC7963'};
border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '5px' };
line-height: ${({ size })=> size ? '24px' : '16px'};
cursor: ${({ buttoncolor, loading }) => (buttoncolor === 'disable' || loading === true) ? 'not-allowed' : 'pointer'};

&:hover {
	color:
		${(
			{
				theme,
				buttoncolor,
				loading
			}
		) => loading === true ?
		theme.oneClass.button.mouseOver.background['grey']
		: buttoncolor === 'cancel' ? theme.palette.primary.main 
		: buttoncolor === 'none' ? theme.palette.btn['nonehover'] : '' };
	background:
		${(
			{ theme,
			buttoncolor,
			loading }
		) => loading === true ?
		theme.oneClass.button.mouseOver.background['grey'] :
		buttoncolor === 'danger' ?
		theme.palette.main.rose :
		buttoncolor !== 'none' &&
		theme.palette.btn[buttoncolor + 'hover']};
	outline:
	  ${({ buttoncolor }) => buttoncolor === 'none' && '1px solid #EE8773'};
	border: ${({buttoncolor}) => buttoncolor === 'standardSecondary' && '0.6px solid #2A2A47'};
}
`;
export const UiCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	color: #fff;
`;
export const UiInlineFlex = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;

	& > svg {
		margin-left: ${({ endIcon }) => endIcon && '5px'};
		font-size: 1.7rem;
	}
`;

import React, { useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { TextField as MatTextField } from '@material-ui/core';

import { Checkbox, Icon,Button } from 'components';
import { FORGETPASSWORD, REGISTER } from 'constants/index';
import { setOnePaperLoginCookieByServiceRoot } from 'helper/onePaper';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
// import { useDetectionDevice } from 'utils/hooks/useDetectionDevice';
import {
  UiLoginForm,
  UiLoginFormFooter,
  UiContactUs,
  UiTypography,
  UiDivider,
  UiRow,
  UiIconList,
  UiIconRow
} from './LoginForm.style';

/**
 * 登入
 */

export const LoginForm = () => {
  const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
  const history = useHistory();
  const [{ profile }, { userLogin,fetchOrganizationById }] = useUser();

  // const { getDeviceInfo, getBrowserInfo } = useDetectionDevice();
  const [{
    username,
    password,
    rememberMe,
    // isModalOpen,
    // modalText,
    isLoading,
   }, setState] = useSetState({
    username: '',
    password:'',
    rememberMe:false,
    isModalOpen: false,
    modalText: '',
    isLoading : false,
  });

  // const { broswer, version } = getBrowserInfo();
  // const device = getDeviceInfo();
  // const getModalStateHandler = state => {
  //   setState({ isModalOpen: state });
  // };
  // const modalButtons = [
  //   {
  //     text: '確定',
  //     func: () => {
  //       getModalStateHandler(false);
  //     }
  //   }
  // ];
  // const getModalText = () => {
  //   switch (device) {
  //     case 'iphone':
  //       if (broswer === 'Safari') {
  //         if (version < 11) {
  //           setState({
  //             modalText: <p>您目前使用的Safari 瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Safari 瀏覽器至11.0之後的版本</p>
  //           });
  //         }
  //       } else {
  //         setState({
  //           modalText: <p>您目前使用的瀏覽器非Safari 瀏覽器，為了確保您能獲得最佳的使用體驗，請改用Safari 瀏覽器。</p>
  //         });
  //       }
  //       break;
  //     default:
  //       if (broswer === 'Chrome') {
  //         if (version < 60) {
  //           setState({
  //             modalText:
  //               <p>
  //                 您目前使用的Chrome瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Chrome瀏覽器至
  //                 <a
  //                   href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
  //                 sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   60之後的版本
  //                 </a>
  //               </p>
  //           });
  //         }
  //       } else {
  //         setState({
  //           modalText:
  //             <p>您目前使用的瀏覽器非Chrome瀏覽器，為了確保您能獲得最佳的使用體驗，請改用
  //             <a
  //                 href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
  //               sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //               >
  //                 Chrome瀏覽器
  //               </a>
  //               。
  //             </p>
  //         });
  //       }
  //       break;
  //   }
  // };
  // useEffect(() => {
  //   getModalText();
  // }, []);
  // useEffect(() => {
  //   if (modalText !== '') {
  //     getModalStateHandler(true);
  //   }
  // }, [modalText]);

  useEffect(()=>{
    if(profile.id){
      fetchOrganizationById(organizationId);
    }
  },[profile]);

  // useEffect(()=>{
  //   if(!profile.id) return;
  //   if(profile.organizations.length >= 1) {
  //     history.push('/home');
  //   }else{
  //     history.push('/tutor');
  //   }
  // },[]);

  const onkeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      submitHandler();
      return false;
    }
  };
  const submitHandler = async () => {
    if(!username || !password) return;
    if(isLoading) return;
    setState({
      isLoading:true
    });
    const formData = {
      username,
      password
    };

    await userLogin(formData);
    rememberMe ? localStorage.setItem('account',username) : localStorage.removeItem('account');
    setState({
      isLoading:false
    });
  };

  const onChangeHandle = (value,key) => {
    setState({
      [key]:value
    });
  };

  const cloudLogin = () => {
    setOnePaperLoginCookieByServiceRoot();
    window.loginNaniOneClass(token => {
      if (!token) return;
      history.push('/home');
    });
  };

  useEffect(() => {
    const account = (localStorage.getItem('account')) === null ? '' : localStorage.getItem('account');
    setState({
      username: account,
      rememberMe: account ? true : false
    });
  }, []);

  return (
    <>
      {/* <Modal
        isOpen={isModalOpen}
        content={modalText}
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      /> */}
      <UiLoginForm>
        {/* <Form schema={schema} buttons={buttons} isLoading={isLoading} onSubmit={submitHandler} /> */}
        <div className="box">
          <MatTextField
            label="帳號"
            value={username}
            variant="outlined"
            size="small"
            onChange={e => onChangeHandle(e.target.value,'username')}
            onKeyPress={onkeyPress}
          />
        </div>
        <div className="box">
          <MatTextField
             label="密碼"
             value={password}
             variant="outlined"
             size="small"
             type="password"
             onChange={e=>onChangeHandle(e.target.value,'password')}
             onKeyPress={onkeyPress}
          />
        </div>
        <div className="box">
          <Checkbox
            label="記住我"
            checkedValue="rememberMe"
            onChange={value=>{
              const isRememberMe = value === 'rememberMe' ? true : false;
              onChangeHandle(isRememberMe,'rememberMe');
            }}
            checked={rememberMe}
          />
        </div>
        <div className="btnBox">
          <Button btnSize="large" loading={isLoading} onClick={submitHandler}>登入</Button>
          <Button
            btnSize="large"
            loading={isLoading}
            buttonColor='ui03'
            textColor="#242C3F"
            onClick={cloudLogin}
          >
              教育雲登入
           </Button>
        </div>
        <UiLoginFormFooter>
          <NavLink to={`/${FORGETPASSWORD}`}>
            忘記密碼
          </NavLink>
          <span>|</span>
          <NavLink to={`/${REGISTER}`}>
            註冊帳號
          </NavLink>
        </UiLoginFormFooter>
      </UiLoginForm>
      <UiRow>
        <UiContactUs>
          <UiTypography variant="h4">聯繫我們</UiTypography>
          <UiDivider />
          <UiIconList>
            <UiIconRow href="tel:+886-2-82263699">
              <Icon name="phone" title="(02) 8226-3699" haveBg={false}/>
              <div>(02) 8226-3699</div>
            </UiIconRow>
            <UiIconRow href="https://lin.ee/FdL4cmD">
              <Icon.Svg name="Line" title="南一客服小幫手Line@"/>
              <div>南一客服小幫手Line@</div>
            </UiIconRow>
            <UiIconRow href="https://cdn.nani.cool/download/%E5%8D%97%E4%B8%80%E9%81%A0%E7%AB%AF%E9%80%A3%E7%B7%9A.exe">
              <Icon name="settingRemote" title="遠端連線" haveBg={false}/>
              <div>遠端連線</div>
            </UiIconRow>
          </UiIconList>
        </UiContactUs>
      </UiRow>
      <UiRow>
        <p>為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為:IE Edge、Google Chrome或Firefox。</p>
        <p>如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。</p>
      </UiRow>
      <UiRow>
        Copyright © 2021 NANI
      </UiRow>
    </>
  );
};


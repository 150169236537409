/**
 * @param draft 草稿
 * @param preparing 準備中
 * @param processing 進行中
 * @param over 已結束
 * @param cancel 已取消
 */
export const COURSE_INTERVAL_STATUS = {
  DRAFT: 'draft',
  PREPARING: 'preparing',
  PROCESSING: 'processing',
  OVER: 'over',
  FINISH: 'finish',
  CANCEL: 'cancel',
};

/**
 * @param none 無異常
 * @param other 其他
 * @param teacherTakeLeave 老師請假
 * @param studentTakeLeave 學生請假(學生取消課程)
 * @param studentAskTransfer 學生要求調課
 * @param studentAskSubstitute 學生要求代課
 * @param studentAskCancel 老師請假 -> 學生要求取消
 * @param teacherAbsenteeism 老師曠課
 * @param teacherLate 老師遲到
 * @param teacherLeaveEarly 老師早退
 * @param studentAbsenteeism 學生曠課
 * @param studentLate 學生遲到
 * @param studentLeaveEarly 學生早退
 * @param tooShortCourse 課程過短
 * @param tooLongCourse 課程超時
 */
// export const COURSE_ISSUE = {
//   NONE: 'none',
//   OTHER: 'other',
//   TEACHER_TAKE_LEAVE: 'teacherTakeLeave',
//   STUDENT_TAKE_LEAVE: 'studentTakeLeave',
//   STUDENT_ASK_TRANSFER: 'studentAskTransfer',
//   STUDENT_ASK_SUBSTITUTE: 'studentAskSubstitute',
//   STUDENT_ASK_CANCEL: 'studentAskCancel',
//   TEACHER_ABSENTEEISM: 'teacherAbsenteeism',
//   TEACHER_LATE: 'teacherLate',
//   TEACHER_LEAVE_EARLY: 'teacherLeaveEarly',
//   STUDENT_ABSENTEEISM: 'studentAbsenteeism',
//   STUDENT_LATE: 'studentLate',
//   STUDENT_LEAVE_EARLY: 'studentLeaveEarly',
//   TOO_SHORT_COURSE: 'tooShortCourse',
//   TOO_LONG_COURSE: 'tooLongCourse',
//   BOTH_ABSENTEEISM: 'bothAbsenteeism',
// };
/**
 * @param [] 無 issue
 * @param other 其他
 * @param teacherTakeLeave 老師請假
 * @param studentTakeLeave 學生請假(學生取消課程)
 * @param studentAskTransfer 學生要求調課
 * @param studentAskSubstitute 學生要求代課
 * @param studentAskCancel 老師請假 -> 學生要求取消
 * @param teacherAbsenteeism 老師曠課
 * @param teacherLate 老師遲到
 * @param teacherLeaveEarly 老師早退
 * @param studentAbsenteeism 學生曠課
 * @param studentLate 學生遲到
 * @param studentLeaveEarly 學生早退
 * @param tooShortCourse 課程過短
 * @param tooLongCourse 課程超時
 * @param teacherTakeLeaveTooLate 老師於24時內請假
 * @param studentTakeLeaveTooLate 學生於24時內請假
 * @param anomaly 上課時間異常
 * @param videoRecordNotWork 錄影沒有啟動
 * @param videoRecordTooShort 錄影太短
 * @param videoRecordTooLong 錄影太長
 */
export const ISSUE_GROUP = {
  OTHER: 'other',
  TEACHER_TAKE_LEAVE: 'teacherTakeLeave',
  STUDENT_TAKE_LEAVE: 'studentTakeLeave',
  STUDENT_ASK_TRANSFER: 'studentAskTransfer',
  STUDENT_ASK_SUBSTITUTE: 'studentAskSubstitute',
  STUDENT_ASK_CANCEL: 'studentAskCancel',
  TEACHER_ABSENTEEISM: 'teacherAbsenteeism',
  TEACHER_LATE: 'teacherLate',
  TEACHER_LEAVE_EARLY: 'teacherLeaveEarly',
  STUDENT_ABSENTEEISM: 'studentAbsenteeism',
  STUDENT_LATE: 'studentLate',
  STUDENT_LEAVE_EARLY: 'studentLeaveEarly',
  TOO_SHORT_COURSE: 'tooShortCourse',
  TOO_LONG_COURSE: 'tooLongCourse',
  TEACHER_TAKE_LEAVE_TOO_LATE: 'teacherTakeLeaveTooLate',
  STUDENT_TAKE_LEAVE_TOO_LATE: 'studentTakeLeaveTooLate',
  ANOMALY: 'anomaly',
  VIDEO_RECORD_NOT_WORK: 'videoRecordNotWork',
  VIDEO_RECORD_TOO_SHORT: 'videoRecordTooShort',
  VIDEO_RECORD_TOO_LONG: 'videoRecordTooLong',
};
export const ISSUE_GROUP_TEXT = {
  [ISSUE_GROUP.OTHER]: '其他' ,
  [ISSUE_GROUP.TEACHER_TAKE_LEAVE]: '老師請假',
  [ISSUE_GROUP.STUDENT_TAKE_LEAVE]: '學生請假',
  [ISSUE_GROUP.STUDENT_ASK_TRANSFER]: '學生要求調課',
  [ISSUE_GROUP.STUDENT_ASK_SUBSTITUTE]: '學生要求代課',
  [ISSUE_GROUP.STUDENT_ASK_CANCEL]: '老師請假，學生要求取消',
  [ISSUE_GROUP.TEACHER_ABSENTEEISM]: '老師曠課',
  [ISSUE_GROUP.TEACHER_LATE]: '老師遲到',
  [ISSUE_GROUP.TEACHER_LEAVE_EARLY]: '老師早退',
  [ISSUE_GROUP.STUDENT_ABSENTEEISM]: '學生曠課',
  [ISSUE_GROUP.STUDENT_LATE]: '學生遲到',
  [ISSUE_GROUP.STUDENT_LEAVE_EARLY]: '學生早退',
  [ISSUE_GROUP.TOO_SHORT_COURSE]: '課程過短',
  [ISSUE_GROUP.TOO_LONG_COURSE]: '課程超時',
  [ISSUE_GROUP.TEACHER_TAKE_LEAVE_TOO_LATE]: '老師於24時內請假',
  [ISSUE_GROUP.STUDENT_TAKE_LEAVE_TOO_LATE]: '學生於24時內請假',
  [ISSUE_GROUP.ANOMALY]: '上課時間異常',
  [ISSUE_GROUP.VIDEO_RECORD_NOT_WORK]: '錄影沒有啟動',
  [ISSUE_GROUP.VIDEO_RECORD_TOO_SHORT]: '錄影太短',
  [ISSUE_GROUP.VIDEO_RECORD_TOO_LONG]: '錄影太長',
};

/**
 * @param individualLiveCourse 家教個人課
 * @param groupLiveCourse 團體課
 */
export const COURSE_TYPE = {
  INDIVIDUAL_LIVE_COURSE: 'individualLiveCourse',
  GROUP_LIVE_COURSE: 'groupLiveCourse',
};

export const COURSE_BTN_STATUS_MAPPING = {
  [COURSE_INTERVAL_STATUS.DRAFT]: 'draft',
  [COURSE_INTERVAL_STATUS.PREPARING]: 'PREPARING',
  [COURSE_INTERVAL_STATUS.FINISH]: 'ending',
  [COURSE_INTERVAL_STATUS.CANCEL]: 'cancel',
};

/**
 * @param reply 待回覆
 * @param accept 已確認
 * @param reject 已拒絕
 */
export const TEACHER_STATUS = {
  REPLY: 'reply',
  ACCEPT: 'accept',
  REJECT: 'reject',
};

export const ALERT_MSG = {
  SUBSTITUTE: {
    SUCCESS: '已送出代課需求，安排完成將通知',
    ERROR: '安排代課失敗',
  },
  TRANSFER: {
    SUCCESS: '已送出調課需求，安排完成將通知',
    ERROR: '安排調課失敗'
  },
  CANCEL: {
    SUCCESS: '已取消課程',
    ERROR: '取消課程失敗'
  }
};

/**
 * live 前台用狀態
 * @param preparing 準備中（含學生請假 - 未處理）
 * @param preparingAgo30 課程進行前 30 分鐘
 * @param processing 進行中
 * @param over 已結束
 * @param overWithin90 課程結束 90 分鐘內
 * @param finish 已完成
 * @param finishWithin90 課程完成 90 分鐘內
 * @param cancel 已取消
 * @param teacherTakeLeave 老師請假
 * @param studentTakeLeave 學生請假
 * @param studentAskTransfer 學生要求調課
 * @param studentAskSubstitute 學生要求代課
 * @param studentAskCancel 學生要求取消
 * @param teacherTakeLeaveTooLate 老師於24時內請假
 * @param studentTakeLeaveTooLate 學生於24時內請假
 */
export const FORESTAGE_COURSE_STATE = {
  PREPARING: 'preparing',
  PREPARING_AGO_30: 'preparingAgo30',
  PROCESSING: 'processing',
  OVER: 'over',
  OVER_WITHIN_90: 'overWithin90',
  FINISH: 'finish',
  FINISH_WITHIN_90: 'finishWithin90',
  CANCEL: 'cancel',
  TEACHER_TAKE_LEAVE: 'teacherTakeLeave',
  STUDENT_TAKE_LEAVE: 'studentTakeLeave',
  STUDENT_ASK_TRANSFER: 'studentAskTransfer',
  STUDENT_ASK_SUBSTITUTE: 'studentAskSubstitute',
  STUDENT_ASK_CANCEL: 'studentAskCancel',
  TEACHER_TAKE_LEAVE_TOO_LATE: 'teacherTakeLeaveTooLate',
  STUDENT_TAKE_LEAVE_TOO_LATE: 'studentTakeLeaveTooLate',
};

/**
 * live 前台用狀態
 * @param preparing 準備中
 * @param preparingAgo30 準備中（開始前30分鐘）
 * @param processing 進行中
 * @param overWithin90 已結束（課程結束後90分鐘內）
 * @param over 已結束
 * @param finishWithin90 已完成（課程完成後90分鐘內）
 * @param finish 已完成
 * @param leave 請假
 * @param cancel 已取消
 */
export const LIVE_COURSE_STATUS = {
  PREPARING: 'preparing',
  PREPARING_AGO_30: 'preparingAgo30',
  PROCESSING: 'processing',
  OVER_WITHIN_90: 'overWithin90',
  OVER: 'over',
  FINISH: 'finish',
  FINISH_WITHIN_90: 'finishWithin90',
  ISSUE: 'issue',
  CANCEL: 'cancel',
};

/* live 需要的 issue */
export const LIVE_REQUEST_ISSUES = [
  ISSUE_GROUP.TEACHER_TAKE_LEAVE,
  ISSUE_GROUP.STUDENT_TAKE_LEAVE,
  ISSUE_GROUP.STUDENT_ASK_TRANSFER,
  ISSUE_GROUP.STUDENT_ASK_SUBSTITUTE,
  ISSUE_GROUP.STUDENT_ASK_CANCEL,
  ISSUE_GROUP.TEACHER_TAKE_LEAVE_TOO_LATE,
  ISSUE_GROUP.STUDENT_TAKE_LEAVE_TOO_LATE,
];

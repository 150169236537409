import styled from 'styled-components';

export const UiRoot = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
`;

export const UiRow = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`;

export const UiBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UiIconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F9C74F;
  border-radius: 50%;
  margin: 0;
  min-width: 36px;
  min-height: 36px;
`;

export const UiEmptyFigure = styled('figure')`
  margin: 0;
  text-align: center;
`;
export const UiEmptyImgContainer = styled('div')`
  margin-bottom: 24px;
`;
export const UiEmptyFigcaption = styled('figcaption')`
  font-size: 0.93rem;
`;

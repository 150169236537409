import styled from 'styled-components';
import { DateTimePicker } from 'components';
import { TextField } from '@material-ui/core';

export const UiRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiHeader = styled.div`
  color: #3A4052;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  white-space: break-spaces;
`;

export const UiBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const UiSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const UiItem = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems ? alignItems : 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection ? flexDirection : 'row'};

  & :first-child {
    margin-right: ${({ mr }) => mr ? mr : 0 };
  };
`;
export const UiSubtitle = styled.span`
  font-size: 16px;
  font-weight: 500;
`;
export const UiDateTimePicker = styled(DateTimePicker).attrs({
  inputVariant: 'outlined',
  format: 'yyyy/MM/dd hh:mm a',
  label: '調課時間*',
})(() => ({
  flex: 1,
}));
export const UiTextArea = styled(TextField)`
  margin-top: 16px;
`;

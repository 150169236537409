import  styled from 'styled-components';
import openCourseBanner from 'assets/images/home_session_banner.svg';
import { Link } from 'react-router-dom';

export const UiBannerWrapper = styled.div`
`;

export const UiBannerFigure = styled.figure`
	margin: 0 0 24px 0;
`;

export const UiBannerImg = styled.img.attrs({
	src: openCourseBanner,
	alt: '前往課外學習',
})`
	cursor: pointer;
	width: 100%;
`;

export const UiBannerFigcaption = styled.figcaption`
	display: flex;
	justify-content: flex-end;
`;

export const UiBannerLink = styled(Link)`
	color: ${({ theme }) => theme.palette.primary.main };
	font-size: 0.93333rem;	/* 14px */
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
import styled from 'styled-components';
import { Grid } from  '@material-ui/core';

export const UiTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;
export const UiTitle = styled.div`
  color: #242C3F;
  font-family: Noto Sans TC;
  font-size: 20px;
  font-weight: 500;
`;
export const UiSubtitle = styled.div`
  color: #8B90A0;
  font-family: Noto Sans TC;
  font-size: 11px;
  font-weight: 400;
`;

export const UiServicesWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
export const UiService = styled(Grid)`
  background: #FAFAFC;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px 16px;
  max-width: 50%;
  cursor: pointer;
`;
export const UiServiceFigure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EC7963;
  margin: 0;
  border-radius: 4px;
  height: 40px;
  width: 40px;
`;
export const UiServiceImg = styled.img`
  height: 100%;
  width: 100%;
`;

export const UiServiceTitle = styled.div`
  color: #454B5C;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 500;
`;
export const UiServiceSubtitle = styled.div`
  color: #8B90A0;
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 400;
`;
import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Modal } from '@material-ui/core';
import SessionIntroduction from './SessionIntroduction';
import SessionService from './SessionService';
import {
  UiModalWelcome,
  UiCloseIconContainer,
} from './ModalWelcome.style';
import { Icon } from 'components';

/**
 * 歡迎彈窗
 */

export const ModalWelcome = ({open, onClose}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
    >
      <Fade in={open}>
        <UiModalWelcome>
          <UiCloseIconContainer>
            <Icon
              icolor='#000'
              haveBg={false}
              name='close'
              onClick={onClose}
            />
          </UiCloseIconContainer>
          <SessionIntroduction onClose={onClose}/>
          <SessionService />
        </UiModalWelcome>
      </Fade>
    </Modal>
  );
};

ModalWelcome.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};



import styled from 'styled-components';
import modalWelcomeBg from 'assets/images/modal_welcome_bg.svg';

export const UiModalWelcome = styled.div`
	display: flex;
	flex-direction: column;
	background: top no-repeat url(${modalWelcomeBg}) #FFF;
	border-radius: 12px;
	width: 640px;
	padding: 108px 64px 44px 64px;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const UiCloseIconContainer = styled.figure`
	margin: 0;
	position: absolute;
	top: 29px;
	right: 29px;
`;
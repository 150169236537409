/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import ActionComponents from './ActionComponents';
import {
  BsModal,
  Table,
  Button,
  Prompt,
  Loading,
  ResourceModal,
  HomeSessionsFilter,
} from 'components';
import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useLocation, } from 'react-router-dom';
import { UiTable } from 'styles';
import { UiFeedbackModalContent } from './HomeSessionsTable.style';


/**
 * 前台課程表
 */

const schema = {
  name: {
    name: '課程名稱',
    defaultValue: ''
  },
  subject:{
    name: '科目',
    defaultValue:'--'
  },
  startDate: {
    name: '日期',
    defaultValue: ''
  },
  startTime: {
    name: '時間',
    defaultValue: ''
  },
  hostName: {
    name: '教師',
    defaultValue: ''
  },
  actionComponents: {
    name: '',
    defaultValue: ''
  }
};


export const HomeSessionsTable = () => {
  const { state } = useLocation();
  const [{ timestamp: serverTimestamp }, { setServerTime }] = useServerTime();
  const [{ myOrganization }] = useUser();
  const [{ sessions }, { getSessions }] = useSessions();
  const [ filterData, setFilterData ] = useState({
    name: '',
    dateInterval: 'ALL',
    startAt: null,
    endAt: null,
  });
  const [
    {
      nowPage,
      rowsPage,
      sessionAttendanceManagementSetting,
      isClick,
      isListLoading,
      isOpenFeedbackModal,
      isOpenResourceModal,
      resourceData,
      tableSessionId,
    }, setState] = useSetState({
      nowPage: state?.nowPage ?? 0,
      rowsPage: 10,
      officialName: '',
      sessionAttendanceManagementSetting: ORGANIZATION_SETTINGS_VALUE.NONE,
      isClick: true,
      isListLoading: true,
      isOpenFeedbackModal: false,
      isOpenResourceModal: false,
      resourceData: [],
      tableSessionId: null,
  });

  const tableData = useMemo(() => 
    sessions.data.map(item => ({
      ...item,
      actionComponents: <ActionComponents
        params={{...item}}
        isClick={isClick}
        nowPage={nowPage}
        sessionAttendanceManagementSetting={sessionAttendanceManagementSetting}
        setState={setState}
        serverTimestamp={serverTimestamp} />,
    }))
  , [sessions]); 

  useEffect(() => {
    if (myOrganization.isLoaded) {
      const { officialName,sessionAttendanceManagementSetting } = myOrganization.organization;
      setState({
        officialName,
        sessionAttendanceManagementSetting
      });
    }
  }, [myOrganization]);

  const changeFilterData = (changedData) => {
    setFilterData(prev => ({...prev, ...changedData}));
    setState({ nowPage: 0 });
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false});
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  useEffect(() => {
    if(!serverTimestamp) return;
    (async () => {
      setState({ isListLoading: true });
      await getSessions({ nowPage, rowsPage, name: filterData.name, startAt: filterData.startAt, endAt: filterData.endAt }, serverTimestamp);
      setState({ isListLoading: false });
    })();
  }, [nowPage, rowsPage, filterData.name, filterData.startAt, filterData.endAt, serverTimestamp]);

  useEffect(() => {
    if(resourceData.length <= 0) return;

    setState({
      resourceValue: resourceData[0].value,
    });
  }, [resourceData]);

  /* 排序 sessions 透過「課程開始時間」 */
  const sortSessionsDataByStartAt = (sessions) => {
    return sessions.sort((a, b) => {
      if(a.startAt < b.startAt) return -1;
      return 0;
    });
  };
  /* 取得距離上課時間最近的一筆課程 */
  const getLatestSession = () => {
    const sortedSessions = sortSessionsDataByStartAt([ ...sessions.data ]);
    sortedSessions.find(session => session.isCanEnterClass.type !== 'end');
  };
  /* 取得課程結束後，button 變成「課程結束」的剩餘時間 */
  const getRemainingTime = (latestSession) => {
    // 90分鐘 + 10秒
    const requiredTimeOfSessionEndBtn = 90 * 60 * 1000 + 10000;
    const { endAt } = latestSession;
    const remainingTime = (endAt + requiredTimeOfSessionEndBtn) - serverTimestamp;
    return remainingTime;
  };
  useEffect(() => {
    if(sessions.data.length === 0) return;
    // 取得距離上課時間最近的一筆課程
    const latestSession = getLatestSession();

    if(!latestSession) return;
    // 取得課程結束後，button 變成「課程結束」的剩餘時間
    const remainingTime = getRemainingTime();

    // 時間到重打 API
    const timeoutId = setTimeout(async () => {
      setServerTime();
    }, remainingTime);
    return () => clearTimeout(timeoutId);
  }, [sessions.data]);

  /* click 課後回饋 - 試聽課 */
  const clickFeedbackTrial = () => {
    window.open('https://demofeedback.oneclass.com.tw/teacher.html', '_blank');
    setState({ isOpenFeedbackModal: false });
  };

  /* click 課後回饋 - 正式課 */
  const clickFeedbackFormal = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLScqdg675ccYb0nHZ9LnvdgX1nVOK5iRuFrVZO7NVxyrplEuQg/viewform', '_blank');
    setState({ isOpenFeedbackModal: false });
  };

  return (
    <UiTable>
      {/* 課程教材 彈窗 */}
      {isOpenResourceModal &&
        <ResourceModal
          sessionId={tableSessionId}
          isOpenResourceModal={isOpenResourceModal}
          onCancel={clickResourceModalCancelHandler}
          onOk={clickResourceModalOkHandler}
        />}

      {/* 課後回饋 彈窗 */}
      {isOpenFeedbackModal &&
        <BsModal
          title='課後回饋'
          open={isOpenFeedbackModal}
          onCancel={() => setState({ isOpenFeedbackModal: false })}
          onClose={() => setState({ isOpenFeedbackModal: false })}
          cancelDisplay={false}
          oKDisplay={false}
        >
          <UiFeedbackModalContent>
            <Button onClick={clickFeedbackTrial}>試聽課</Button>
            <Button onClick={clickFeedbackFormal}>正式課</Button>
          </UiFeedbackModalContent>
        </BsModal>}

      <HomeSessionsFilter
        filterData={filterData}
        changeFilterData={changeFilterData}
      />

      {isListLoading && <Loading />}
      {sessions.data.length === 0
        ? (!isListLoading) && <Prompt message="目前暫無課程"></Prompt>
        : <Table
            data={tableData}
            totalPage={sessions.total}
            schema={schema}
            changePage_Rows={changePage_Rows}
            nowPage={nowPage}
          />}
    </UiTable>
  );
};

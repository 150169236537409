/**
 * debounce function
 * @param {() => void} fn debounce function
 * @param {number} ms wait time
 */
export default function(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlertMessage, HomeGroupLayout, HomeSessionsBanner, HomeSessionsTab, Icon } from 'components';
import { useClass } from 'store/class';
import { useTheme } from '@material-ui/core/styles';


/**
 * 前台課表
 */

const ALERT_MSG = '課程列表正在逐步改版中，因此即日起新增的課程將顯示在「新版課程列表」喔！';

export const HomeSessionPage = () => {
  const theme = useTheme();
  const { classId } = useParams();
  const [, { getSubjects }] = useClass();
  const [isOpenAlertMsg, setIsOpenAlertMsg] = useState(true);

  useEffect(() => {
    getSubjects(classId);
  }, [classId]);

  return (
    <HomeGroupLayout>
      <HomeSessionsBanner />
      <AlertMessage
        msg={ALERT_MSG}
        open={isOpenAlertMsg}
        close={() => setIsOpenAlertMsg(false)}
        icon={<Icon.Svg name='Bullhorn' color={theme.palette.text.primary03}/>}
      />
      <HomeSessionsTab />
    </HomeGroupLayout>
  );
};


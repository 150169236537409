import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { UiRoot } from './Tooltip.style';

export const Tooltip = ({
  children,
  title,
  lineClamp,
}) => {
  return (
    <UiRoot lineClamp={lineClamp}>
      {title
        ? <MuiTooltip title={title}>
            {children}
          </MuiTooltip>
        : children
      }
    </UiRoot>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  lineClamp: PropTypes.number,
};
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import Toolbar from './Toolbar';
import { UiCalendar, UiLoading, UiScreen } from './Calendar.style';
import { Loading } from 'components';
import { useWindowSize } from 'utils/hooks/useWindowSize';

const scrollTime = '8:00:00';

const dayList = ['日', '一', '二', '三', '四', '五', '六'];

const viewOptions = [
  {
    name: 'viewWeek',
    title: '週',
    value: 'timeGridWeek',
  },
  {
    name: 'viewDay',
    title: '天',
    value: 'timeGrid',
  },
  {
    name: 'viewAgenda',
    title: '時間表',
    value: 'listWeek',
  },
];


export const Calendar = ({ events, eventClick, loading, setEvents }) => {
  const [width, height] = useWindowSize(250);
  const calendarRef = useRef();
  const calendarApi = calendarRef.current?.getApi();
  const [date, setDate] = useState(new Date());
  const [toolbarTitle, setToolbarTitle] = useState(null);   // Toolbar title
  const [isLoading, setIsLoading] = useState(true);

  /**
   * calendar 寬高比
   * @returns {number} aspectRatio
   */
  const aspectRatio = width / (height - 260);

  /**
   * calendar 左側時間格式化
   * @param {object} date calendar 的 date 物件
   * @returns 
   */
  const slotLabelFormat = ({ date }) => {
    const meridiemIndicator = date.hour >= 12 ? 'pm': 'am';
    const hour = date.hour % 12 || 12;
    return `${hour}${meridiemIndicator}`;
  };
  /**
   * calendar header 日期格式化
   * @param {object} date calendar 的 date 物件
   * @returns 
   */
  const dayHeaderFormat = ({ date }) => {
    const month = date.month + 1;
    const dateDay = date.day;
    const day = dayList[new Date(date.marker).getDay()];
    const space = '\u00A0';
    return `${month}/${dateDay}${space}${space}(${day})`;
  };

  /**
   * change 行事曆顯示方式
   * @param {string} newView 選到的按鈕的 value
   */
  const changeView = (newView) => {
    calendarApi.changeView(newView);
    setEvents(calendarApi.view);
    setToolbarTitle(calendarApi.view.title);
  };

  /**
   * click Toolbar 左邊 icon
   */
  const clickPrev = () => {
    calendarApi.prev();
    setEvents(calendarApi.view);
    setDate(calendarApi.getDate());
    setToolbarTitle(calendarApi.view.title);
  };
  
  /**
   * click Toolbar 右邊 icon
   */
  const clickNext = () => {
    calendarApi.next();
    setEvents(calendarApi.view);
    setDate(calendarApi.getDate());
    setToolbarTitle(calendarApi.view.title);
  };

  /**
   * click Toolbar 今日
   */
  const clickToday = () => {
    calendarApi.today();
    setEvents(calendarApi.view);
    setDate(calendarApi.getDate());
    setToolbarTitle(calendarApi.view.title);
  };

  useEffect(() => {
    if (!calendarApi) return;
    setEvents(calendarApi.view);
    setToolbarTitle(calendarApi.view.title);
  }, [calendarApi]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return(
    <UiCalendar>
      {
        isLoading &&
          <UiScreen toolbarHeight={80}>
            <UiLoading>
              <Loading />
            </UiLoading>
          </UiScreen>
      }
      
      <Toolbar
        onChangeView={changeView}
        onClickNext={clickNext}
        onClickPrev={clickPrev}
        onClickToday={clickToday}
        title={toolbarTitle}
        viewOptions={viewOptions}
      />
      <FullCalendar
        allDaySlot={false}     // 隱藏 all-day
        dayHeaderFormat={dayHeaderFormat} // 日期格式化
        events={events}
        eventClick={eventClick}
        firstDay={1}           // 一週從禮拜一開始
        headerToolbar={false}  // 隱藏 toolbar
        initialDate={date}
        initialView='timeGridWeek'
        locale='zh-TW'         // 顯示中文
        loading={state=>setIsLoading(state)}
        plugins={[ dayGridPlugin, listPlugin, timeGridPlugin ]}
        ref={calendarRef}
        slotLabelFormat={slotLabelFormat} // 時間格式化
        scrollTime={scrollTime} // 左側預設開始時間
        aspectRatio={aspectRatio} // 寬高比
      />
    </UiCalendar>
  );
};

Calendar.propTypes = {
  events: PropTypes.object,
  eventClick: PropTypes.func,
  loading: PropTypes.bool,
  setEvents: PropTypes.func,
};

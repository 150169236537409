import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconInput, Select, TimePicker } from 'components';
import { UiActionBox, UiflexBox } from 'styles';
import { useServerTime } from 'utils/hooks/useServerTime';

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';
const selectOptions = [
  { name: '今日課程', value: NOW },
  { name: '當前課程', value: PROCESSING },
  { name: '已上課程', value: PAST },
  { name: '未來課程', value: FUTURE },
  { name: '所有課程', value: ALL },
];

export const HomeSessionsFilter = ({
  filterData,
  changeFilterData
}) => {
  const [
    { allInterval, futureInterval, pastInterval, processingInterval, todayInterval, timestamp },
    { setServerTime }
  ] = useServerTime();

  const onSelectChangeHandler = selected => {
    let dateParams = {};
    switch (selected) {
      case NOW:
        dateParams = { ...todayInterval };
        break;
      case PAST:
        dateParams = { ...pastInterval };
        break;
      case PROCESSING:
        setServerTime();
        dateParams = { ...processingInterval };
        break;
      case FUTURE:
        dateParams = { ...futureInterval };
        break;
      case ALL:
        dateParams = { ...allInterval };
        break;
      default:
        break;
    }

    changeFilterData({
      dateInterval: selected,
      startAt: dateParams.startAt,
      endAt: dateParams.endAt,
    });
  };

  useEffect(() => {
    if (!timestamp) return;
    onSelectChangeHandler(filterData.dateInterval);
  }, [timestamp]);

  return (
    <UiActionBox>
      <UiflexBox>
        <IconInput
          placeholder='搜尋課程名稱'
          onClick={value => changeFilterData({ name: value })}
          onChange={value => changeFilterData({ name: value })}
        />
        {
          filterData.dateInterval === NOW &&
          <>
            <TimePicker
              label="選擇課程開始時間"
              value={filterData.startAt}
              onChange={value => changeFilterData({ startAt: value })}
            />
            <TimePicker
              label="選擇課程結束時間"
              value={filterData.endAt}
              onChange={value => changeFilterData({ endAt: value })}
            />
          </>
        }

      </UiflexBox>
      <UiflexBox>
        <Select
          options={selectOptions}
          submitHandler={value => onSelectChangeHandler(value)}
          label="課程區間"
          value={filterData.dateInterval}
        />
      </UiflexBox>
    </UiActionBox>
  );
};

HomeSessionsFilter.propTypes = {
  filterData: PropTypes.shape({
    name: PropTypes.string,
    dateInterval: PropTypes.string,
    startAt: PropTypes.number,
    endAt: PropTypes.number,
  }),
  changeFilterData: PropTypes.func,
};
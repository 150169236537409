import { Api } from 'services/api';

/**
 * 取得課後回饋
 * @param {string} teacherId 
 * @param {string} courseId
 * @returns 
 */
 export const getCourseFeedback = async (teacherId, courseId) => {
  const endpoint = `${process.env.REACT_APP_ONECLUB_API_DOMAIN}/feedback-live/${courseId}/${teacherId}`;
  const response = await Api.get(endpoint);
  return response;
};
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HomeSessionsTable, HomeSessionsTableNew, Tab } from 'components';

export const HomeSessionsTab = () => {
  const { classId } = useParams();
  const history = useHistory();

  const tabs = [
    { label: '課程列表', Component: HomeSessionsTable },
    { label: '新版課程列表', Component: HomeSessionsTableNew },
  ];

  const getTabIndex = (tabIndex) => {
    history.push({ pathname: `/home/${classId}/sessions`, search: `?tab=${tabIndex}` });
  };

  return (
    <Tab data={tabs} getTabIndex={getTabIndex} />
  );
};
export const CHART_STATE_MAP = {
  academic: '學科力',
  concentration: '專心度',
  understanding: '理解力',
  reaction: '反應力',
  participation: '參與度',
};

export const chartOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [
      CHART_STATE_MAP.academic,
      CHART_STATE_MAP.concentration,
      CHART_STATE_MAP.understanding,
      CHART_STATE_MAP.reaction,
      CHART_STATE_MAP.participation,
    ],
  },
  yaxis: {
    tickAmount: 5,
    max: 100,
    min: 0,
  },
  fill: {
    opacity: 0.24,
    colors: ['#3366FF'],
  },
  plotOptions: {
    radar: {
      polygons: {
        strokeColor: '#DFE3E8',
        fill: {
          colors: ['#F4F6F8', '#fff'],
        },
      },
    },
  },
};

export const options = [
  {
    label: '超棒',
    value: 'a',
  },
  {
    label: '優良',
    value: 'b',
  },
  {
    label: '好',
    value: 'c',
  },
  {
    label: '可加強',
    value: 'd',
  },
  {
    label: '需改善',
    value: 'e',
  },
];

export const COURSE_TYPE_MAPPING = {
  individualLiveCourse: '家教個人課',
  groupLiveCourse: '家教團體課',
};

export const ERR_MSG = {
  fetchUserRecords: '無法取得 OneBoard 資料',
};

/**
 * @description 優點
 * @property QUICK_RESPONSE 反應力快
 * @property CHEERFUL 開朗活潑
 * @property ENTERPRISING 富進取心
 * @property PROACTIVE 積極回答
 * @property ARTICULATE 口條清晰
 * @property STRONG_COMPREHENSION 理解力強
 * @property GOOD_LEARNING_ABILITY 學習力佳
 * @property DILIGENT_AND_HARDWORKING 認真上進
 * @property OBSERVANT 觀察入微
 * @property GOOD_COMMUNICATION_SKILLS 良好溝通
 * @property CLEAR_THINKING 思路清晰
 * @property INITIATIVE_IN_ASKING_QUESTIONS 主動提問
 * @property RICH_CREATIVITY 創造力豐富
 * @property FLUENT_PRONUNCIATION 流利的發音
 * @property SUFFICIENT_VOCABULARY 單字量夠
 * @property GOOD_COMMAND_OF_GRAMMAR 善用句型
 * @property RICH_VOCABULARY 詞語量多
 * @property GOOD_AT_THINKING 善於思辨
 * @property ACCURATE_CALCULATION  計算準確
 * @property STRONG_REASONING 推理力強
 * @property STRONG_ANALYSIS 分析力強
 * @property CLEAR_LOGICAL_THINKING 運算邏輯清楚
 * @property METICULOUS_THINKING 思考縝密
 */
export const ADVANTAGE = {
  QUICK_RESPONSE: '0',
  CHEERFUL: '1',
  ENTERPRISING: '2',
  PROACTIVE: '3',
  ARTICULATE: '4',
  STRONG_COMPREHENSION: '5',
  GOOD_LEARNING_ABILITY: '6',
  DILIGENT_AND_HARDWORKING: '7',
  OBSERVANT: '8',
  GOOD_COMMUNICATION_SKILLS: '9',
  CLEAR_THINKING: '10',
  INITIATIVE_IN_ASKING_QUESTIONS: '11',
  RICH_CREATIVITY: '12',
  FLUENT_PRONUNCIATION: '13',
  SUFFICIENT_VOCABULARY: '14',
  GOOD_COMMAND_OF_GRAMMAR: '15',
  RICH_VOCABULARY: '16',
  GOOD_AT_CRITICAL_THINKING: '17',
  ACCURATE_CALCULATION: '18',
  STRONG_REASONING: '19',
  STRONG_ANALYSIS: '20',
  CLEAR_LOGICAL_THINKING: '21',
  METICULOUS_THINKING: '22',
};
export const ADVANTAGE_TEXT = {
  [ADVANTAGE.QUICK_RESPONSE]: '反應力快',
  [ADVANTAGE.CHEERFUL]: '開朗活潑',
  [ADVANTAGE.ENTERPRISING]: '富進取心',
  [ADVANTAGE.PROACTIVE]: '積極回答',
  [ADVANTAGE.ARTICULATE]: '口條清晰',
  [ADVANTAGE.STRONG_COMPREHENSION]: '理解力強',
  [ADVANTAGE.GOOD_LEARNING_ABILITY]: '學習力佳',
  [ADVANTAGE.DILIGENT_AND_HARDWORKING]: '認真上進',
  [ADVANTAGE.OBSERVANT]: '觀察入微',
  [ADVANTAGE.GOOD_COMMUNICATION_SKILLS]: '良好溝通',
  [ADVANTAGE.CLEAR_THINKING]: '思路清晰',
  [ADVANTAGE.INITIATIVE_IN_ASKING_QUESTIONS]: '主動提問',
  [ADVANTAGE.RICH_CREATIVITY]: '創造力豐富',
  [ADVANTAGE.FLUENT_PRONUNCIATION]: '流利的發音',
  [ADVANTAGE.SUFFICIENT_VOCABULARY]: '單字量夠',
  [ADVANTAGE.GOOD_COMMAND_OF_GRAMMAR]: '善用句型',
  [ADVANTAGE.RICH_VOCABULARY]: '詞語量多',
  [ADVANTAGE.GOOD_AT_CRITICAL_THINKING]: '善於思辨',
  [ADVANTAGE.ACCURATE_CALCULATION]: '計算準確',
  [ADVANTAGE.STRONG_REASONING]: '推理力強',
  [ADVANTAGE.STRONG_ANALYSIS]: '分析力強',
  [ADVANTAGE.CLEAR_LOGICAL_THINKING]: '運算邏輯清楚',
  [ADVANTAGE.METICULOUS_THINKING]: '思考縝密',
};

/**
 * @description 待加強
 * @property SHY 害羞
 * @property IMPATIENT 沒耐心
 * @property POOR_ORGANIZATION 統整能力較弱
 * @property LOW_INITIATIVE 積極度待加強
 * @property INATTENTIVE 細心度不夠
 * @property FEARFUL_OF_PUBLIC_SPEAKING 害怕發表
 * @property SLOW_THINKING 思考較慢
 * @property WEAK_MEMORY 記憶力較弱
 * @property SLOW_REACTION 反應較慢
 * @property NEED_TO_IMPROVE_VOCABULARY 需增加單字量
 * @property IMPROVE_PRONUNCIATION_ACCURACY 加強發音準確度
 * @property NEED_TO_PRACTICE_READING 需多練習閱讀
 * @property WEAK_SENTENCE_CONSTRUCTION 構句不夠強
 * @property INADEQUATE_COMPREHENSION 語意理解不適切
 * @property UNFAMILIARITY_WITH_FORMULAS 公式不熟悉
 * @property SLOW_CALCULATION 計算比較慢
 * @property WEAKNESS_IN_PROBLEM_SOLVING_SKILLS 應用題型判斷較弱
 * @property NEED_TO_STRENGTHEN_FUNDAMENTAL_CONCEPTS 需加強基本概念
 * @property POOR_VISUALIZATION_ABILITY 圖形理解力較弱
 */
export const IMPROVEMENT = {
  SHY: '0',
  IMPATIENT: '1',
  POOR_ORGANIZATION: '2',
  LOW_INITIATIVE: '3',
  INATTENTIVE: '4',
  FEARFUL_OF_PUBLIC_SPEAKING: '5',
  SLOW_THINKING: '6',
  WEAK_MEMORY: '7',
  SLOW_REACTION: '8',
  NEED_TO_IMPROVE_VOCABULARY: '9',
  IMPROVE_PRONUNCIATION_ACCURACY: '10',
  NEED_TO_PRACTICE_READING: '11',
  WEAK_SENTENCE_CONSTRUCTION: '12',
  INADEQUATE_COMPREHENSION: '13',
  UNFAMILIARITY_WITH_FORMULAS: '14',
  SLOW_CALCULATION: '15',
  WEAKNESS_IN_PROBLEM_SOLVING_SKILLS: '16',
  NEED_TO_STRENGTHEN_FUNDAMENTAL_CONCEPTS: '17',
  POOR_VISUALIZATION_ABILITY: '18',
};
export const IMPROVEMENT_TEXT = {
  [IMPROVEMENT.SHY]: '害羞',
  [IMPROVEMENT.IMPATIENT]: '沒耐心',
  [IMPROVEMENT.POOR_ORGANIZATION]: '統整能力較弱',
  [IMPROVEMENT.LOW_INITIATIVE]: '積極度待加強',
  [IMPROVEMENT.INATTENTIVE]: '細心度不夠',
  [IMPROVEMENT.FEARFUL_OF_PUBLIC_SPEAKING]: '害怕發表',
  [IMPROVEMENT.SLOW_THINKING]: '思考較慢',
  [IMPROVEMENT.WEAK_MEMORY]: '記憶力較弱',
  [IMPROVEMENT.SLOW_REACTION]: '反應較慢',
  [IMPROVEMENT.NEED_TO_IMPROVE_VOCABULARY]: '需增加單字量',
  [IMPROVEMENT.IMPROVE_PRONUNCIATION_ACCURACY]: '加強發音準確度',
  [IMPROVEMENT.NEED_TO_PRACTICE_READING]: '需多練習閱讀',
  [IMPROVEMENT.WEAK_SENTENCE_CONSTRUCTION]: '構句不夠強',
  [IMPROVEMENT.INADEQUATE_COMPREHENSION]: '語意理解不適切',
  [IMPROVEMENT.UNFAMILIARITY_WITH_FORMULAS]: '公式不熟悉',
  [IMPROVEMENT.SLOW_CALCULATION]: '計算比較慢',
  [IMPROVEMENT.WEAKNESS_IN_PROBLEM_SOLVING_SKILLS]: '應用題型判斷較弱',
  [IMPROVEMENT.NEED_TO_STRENGTHEN_FUNDAMENTAL_CONCEPTS]: '需加強基本概念',
  [IMPROVEMENT.POOR_VISUALIZATION_ABILITY]: '圖形理解力較弱',
};

/**
 * @description 機會
 * @property MORE_DISCUSSION_SPACE 提供更多討論空間能提升學習效果
 * @property QUANTIFY_MISTAKES 協助學生量化錯題可釐清迷思概念
 * @property BUILD_CORRECT_CONCEPTS 建立正確觀念協助孩子邏輯思考
 * @property DIGITAL_INTERACTION 透過數位化的互動方式可提升學習動機
 * @property CROSS_DOMAIN_KNOWLEDGE 補充跨領域知識面向增加深度與廣度
 * @property INSPIRE_LEARNING_FUN 透過線上課程安排啟發學習樂趣
 * @property BASIC_EXERCISES 增加基礎練習建立學習信心
 * @property ADVANCED_EXERCISES 增加進階練習挑戰自我極限
 * @property EARLY_LEARNING 孩子可超前學習，發揮天賦
 */
export const CHANCE = {
  MORE_DISCUSSION_SPACE: '0',
  QUANTIFY_MISTAKES: '1',
  BUILD_CORRECT_CONCEPTS: '2',
  DIGITAL_INTERACTION: '3',
  CROSS_DOMAIN_KNOWLEDGE: '4',
  INSPIRE_LEARNING_FUN: '5',
  BASIC_EXERCISES: '6',
  ADVANCED_EXERCISES: '7',
  EARLY_LEARNING: '8',
};
export const CHANCE_TEXT = {
  [CHANCE.MORE_DISCUSSION_SPACE]: '提供更多討論空間能提升學習效果',
  [CHANCE.QUANTIFY_MISTAKES]: '協助學生量化錯題可釐清迷思概念',
  [CHANCE.BUILD_CORRECT_CONCEPTS]: '建立正確觀念協助孩子邏輯思考',
  [CHANCE.DIGITAL_INTERACTION]: '透過數位化的互動方式可提升學習動機',
  [CHANCE.CROSS_DOMAIN_KNOWLEDGE]: '補充跨領域知識面向增加深度與廣度',
  [CHANCE.INSPIRE_LEARNING_FUN]: '透過線上課程安排啟發學習樂趣',
  [CHANCE.BASIC_EXERCISES]: '增加基礎練習建立學習信心',
  [CHANCE.ADVANCED_EXERCISES]: '增加進階練習挑戰自我極限',
  [CHANCE.EARLY_LEARNING]: '孩子可超前學習，發揮天賦',
};
import styled from 'styled-components';

export const UiRoot = styled.div`
  border-bottom: 1px solid #E4E7EC;
	margin-bottom: 20px;
`;

export const UiTitle = styled.div`
	color: #242C3F;
	font-family: Open Sans;
	font-size: 32px;
	font-weight: 600;
	white-space: pre-line;
	margin-bottom: 20px;
`;

export const UiIntro = styled.div`
	color: #454B5C;
	font-family: Noto Sans TC;
	font-size: 16px;
	font-weight: 400;
  margin-bottom: 12px;
`;

export const UiStartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UiRocketContainer = styled.figure`
  margin: 0;
  width: 113px;
`;
export const UiRocketImg = styled.img`
  height: 100%;
  width: 100%;
`;
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, } from 'react-router-dom';
import { Header, SessionGroup } from 'components';
import { getPageName } from 'utils/pagePathSlicer';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useSetState } from 'utils/hooks/useSetState';
import logo from 'assets/images/logo.svg';
import { UiSingleLayout,UiSessionNum } from './SingleLayout.style';


/**
 * 沒有側邊欄的版型
 */

 const GREETINGS = {
  MORNING: '早安',
  AFTERNOON: '午安',
  NIGHT: '晚安'
};

export const SingleLayout = ({ children }) => {
  const { pathname } = useLocation();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const [{ sessionsNum }, setState] = useSetState({
    sessionsNum: null,
  });

  const getTimeState = () => {
    const nowHour = new Date(serverTimestamp).getHours();
    if (nowHour >= 0 && nowHour <= 12) {
      return GREETINGS.MORNING;
    } else if (nowHour > 12 && nowHour <= 18) {
      return GREETINGS.AFTERNOON;
    } else if (nowHour > 18 && nowHour <= 24) {
      return GREETINGS.NIGHT;
    }
  };

  const getSessionNum = num => setState({sessionsNum:num >= 100 ? '99+' : num});

  return (
    <UiSingleLayout>
      <div className="leftSidebar">
        <div className="logo">
          <Link to="/home">
            <img src={logo} alt="live真人一對一教學"/>
          </Link>
        </div>
        <div className="greetings">{getTimeState()} !</div>
        <div className="main">
          <div className="subTitle">
            <div>
              今日課程
              {sessionsNum !== null && <UiSessionNum>{sessionsNum}</UiSessionNum>}
            </div>
            <Link 
              className="readMore" 
              to="/sessions" 
              data-ga-category={getPageName(pathname)}
              data-ga-action='查看課程'
            >
              查看課程
            </Link>
          </div>
          <SessionGroup getSessionNum={getSessionNum}/>
        </div>
      </div>
      <Header className="header" isNeedLogo={false}/>
      <div className="main">
        {children}
      </div>
    </UiSingleLayout>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};



import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';

export const UiAlert = styled(Alert)(({ theme }) => ({
  background: '#EEF5F6',
  
  '& .MuiAlert-icon': {
    padding: 0,
  },

  '& .MuiAlert-message': {
    display: 'flex',
    alignItems: 'center',
    color: '#242C3F',
  }
}));
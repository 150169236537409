import { Api } from 'services/api';
import { stringify } from 'query-string';

/**
 * 取得所有公開課資訊
 * @param {object} params 
 * @returns 
 */
export const getPublished = async (params) => {
  const querystring = stringify(params);
  const endpoint = `${process.env.REACT_APP_COURSES_DOMAIN}/api/courses/published?${querystring}`;
  const response = await Api.get(endpoint);
  return response;
};

/**
 * 取得所有公開課資訊
 * @param {object} params 
 * @returns 
 */
 export const getTagResource = async (type, codeId) => {
  const endpoint = `${process.env.REACT_APP_COURSES_DOMAIN}/api/tag/${type}/${codeId}`;
  const response = await Api.get(endpoint);
  return response;
};

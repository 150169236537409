import styled from 'styled-components';

export const UiRoot = styled.div`
	display: block;
`;


export const UiTitleWrapper = styled.div`
	margin-bottom: 38px;
`;
export const UiTitle = styled.div`
	color: #242C3F;
	font-family: Noto Sans TC;
	font-size: 1.6rem;
	font-weight: 500;
`;


export const UiServicesWrapper = styled.div`
`;
export const UiServiceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
`;

export const UiService = styled.div`
	display: flex;
	gap: 16px;
`;
export const UiServiceFigure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EC7963;
  margin: 0;
  border-radius: 8px;
  height: 64px;
  width: 64px;
`;

export const UiServiceIntroContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
export const UiServiceTitle = styled.div`
  color: #454B5C;
  font-family: Noto Sans TC;
  font-size: 18px;
  font-weight: 600;
`;
export const UiServiceSubtitle = styled.div`
  color: #454B5C;
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 400;
`;
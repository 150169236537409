import React,{ useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { availableEntryNavigation } from 'constants/index';
import { useHistory } from 'react-router-dom';
import defaultImage from 'assets/images/organization.png';
import { Loading } from 'components';
import { useUser } from 'store/user';
import {
  getGroupsByOrganizationId as getGroupsByOrganizationIdApi } from 'services/api/home/class';
import { UiGroupCard,UiGroupsImage,UiRoleIcon,UiGroupsPrompt } from './GroupCard.style';


/**
 * 班級卡牌
 */


 const RoleIcon = ({ role,ownerId,userId }) => {
  const nextRole = ownerId === userId ? 'groupOwner' : role;
  switch (nextRole) {
    case 'groupOwner':
      return (
        <div className="tag">導師</div>
      );
    case 'staff':
      return (
        <div className="tag">教師</div>
      );
    case 'customer':
      return (
        <div className="tag">學生</div>
      );
    default:
      return '';
  }
};

const educationNameFormat = educationName => {
  const educationNameText = {
    E: '國小',
    J: '國中',
    H: '高中'
  };
  return educationNameText[educationName];
};

const gradesFormat = arr => {
  if (arr.length === 0) return '';
  let str = '';
  arr.forEach(item => {
    str += `${item},`;
  });
  return `${str.substring(0, str.length - 1)} 年級  - `;
};

export const GroupCard = ({ organizationId ,year,role }) => {
  const [groupData,setState] = useState([]);
  const [isLoading,setLoadingState] = useState(true);
  const history = useHistory();
  const [
    { profile }
  ] = useUser();
  const fetchGroupsById = async() => {
    const params = {
      organizationId,
      schoolYear:year
    };
    const { data } = await getGroupsByOrganizationIdApi(params);
    setLoadingState(false);
    setState(data);
  };

  const goMyGroup = (classId,entryNavigationFunction) => {
    history.push(`/home/${classId}/${availableEntryNavigation[entryNavigationFunction]}?tab=1`);
  };

  useEffect(()=>{
    if(!organizationId) return;
    fetchGroupsById();
  },[organizationId,year]);

  return (
    <>
      {
        isLoading ? <Loading/> :
        (groupData.length > 0 ? groupData.map(item=>{
          return (
            <UiGroupCard
              key={item.id}
              onClick={() => goMyGroup(item.id,item.entryNavigationFunction)}
            >
              <UiGroupsImage
                url={item.thumbnailUrl || defaultImage}
              />
              <UiRoleIcon>
                <RoleIcon role={item.organizationRole} ownerId={item.ownerId} userId={profile.id}/>
              </UiRoleIcon>
              <div>
                <div>
                  {educationNameFormat(item.educationName)}
                  {item.year && `${item.year}學年度`}
                </div>
                <div>{item.grades && gradesFormat(item.grades)}{item.name}</div>
              </div>
            </UiGroupCard>
          );
        }):
        <UiGroupsPrompt>
          {
            role === 'staff' ? '您目前尚未被安排為授課教師或導師，請聯絡該機構相關負責人員' : '尚未加入班級'
          }
        </UiGroupsPrompt>)
      }
    </>
  );
};

GroupCard.propTypes = {
  organizationId:PropTypes.string ,
  year:PropTypes.any,
  role:PropTypes.string,
};



import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Button, Icon, Tooltip } from 'components';
import emptyImg from 'assets/images/empty_potted_plant.png';
import { HomeSessionsTableNewContext } from 'components/organisms/HomeSessionsTableNew/HomeSessionsTableNew.context';
import {
  UiRoot,
  UiRow,
  UiBox,
  UiIconContainer,
  UiEmptyFigure,
  UiEmptyImgContainer,
  UiEmptyFigcaption,
} from '../common.style';

const EMPTY_MSG = '目前尚無作業';

export const Homework = () => {
  const { course } = useContext(HomeSessionsTableNewContext);
  const hasHomeworks = !!course?.homeworks.length;

  const clickDownload = (homework) => () => {
    const { filename, seriesCode } = homework;
    const url = `${process.env.REACT_APP_CDN_LIBRARY}/${seriesCode}/homework/${filename[0]}`;
    fetch(url, { method: 'GET' })
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename[0]);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <UiRoot>
      {hasHomeworks
        ? course?.homeworks.map(homework =>
          <UiRow key={homework.id}>
            <UiBox>
              <UiIconContainer>
                <Icon.Svg
                  name="Homework"
                  color="#FFF"
                />
              </UiIconContainer>
              <Tooltip
                title={homework.name}
                lineClamp="2"
              >
                <Typography>{homework.name}</Typography>
              </Tooltip>
            </UiBox>
            <UiBox>
              <Button
                buttonColor="new"
                onClick={clickDownload(homework)}
              >下載檔案</Button>
            </UiBox>
          </UiRow>)
        : <UiEmptyFigure>
            <UiEmptyImgContainer>
              <img alt="emptyImg" src={emptyImg} />
            </UiEmptyImgContainer>
            <UiEmptyFigcaption>{EMPTY_MSG}</UiEmptyFigcaption>
          </UiEmptyFigure>}
    </UiRoot>
  );
};
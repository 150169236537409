import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Button, Icon, Tooltip } from 'components';
import emptyImg from 'assets/images/empty_potted_plant.png';
import { HomeSessionsTableNewContext } from 'components/organisms/HomeSessionsTableNew/HomeSessionsTableNew.context';
import {
  UiRoot,
  UiRow,
  UiBox,
  UiIconContainer,
  UiEmptyFigure,
  UiEmptyImgContainer,
  UiEmptyFigcaption,
} from '../common.style';

const EMPTY_MSG = '目前尚無教材';

export const Resource = () => {
  const { course } = useContext(HomeSessionsTableNewContext);
  const hasResources = !!course?.resources.length;

  const goToReader = (resource) => () => {
    const { bookId } = resource;
    const readerUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${bookId}`;
    window.open(readerUrl, '_blank'); 
  };

  return (
    <UiRoot>
      {hasResources
        ? course.resources.map(resource =>
            <UiRow key={resource.id}>
              <UiBox>
                <UiIconContainer>
                  <Icon.Svg
                    name="BookSolid"
                    color="#FFF"
                  />
                </UiIconContainer>
                <Tooltip
                  title={resource?.tip}
                  lineClamp="2"
                >
                  <Typography>{resource.name}</Typography>
                </Tooltip>
              </UiBox>
              <UiBox>
                <Button
                  buttonColor="new"
                  onClick={goToReader(resource)}
                >
                  開啟教材
                </Button>
              </UiBox>
            </UiRow>)
        : <UiEmptyFigure>
            <UiEmptyImgContainer>
              <img alt="emptyImg" src={emptyImg} />
            </UiEmptyImgContainer>
            <UiEmptyFigcaption>{EMPTY_MSG}</UiEmptyFigcaption>
          </UiEmptyFigure>}
    </UiRoot>
  );
};
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Button, Icon, Loading, Tooltip } from 'components';
import emptyImg from 'assets/images/empty_potted_plant.png';
import { HomeSessionsTableNewContext } from 'components/organisms/HomeSessionsTableNew/HomeSessionsTableNew.context';
import { useParams } from 'react-router-dom';
import { createExam as createExamApi } from 'services/api/exam';
import { getExams as getExamsApi } from 'services/api/oneClub/course';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { closeWindow } from 'utils/window';
import {
  UiRoot,
  UiRow,
  UiBox,
  UiIconContainer,
  UiEmptyFigure,
  UiEmptyImgContainer,
  UiEmptyFigcaption,
} from '../common.style';

const EMPTY_MSG = '目前尚無測驗';
const FETCH_EXAMS_ERR = '取得測驗失敗';
const SERVICE = 'oneLive';
const PAPER_TYPE = 'course';
const EXAM_DOMAIN = process.env.REACT_APP_ONEEXAM_DOMAIN;

/**
 * step:
 * 1. 取得 paper
 * 2. paper 是否建立 exam
 *    a. 已建立 -> 3.
 *    b. 未建立 -> 建立測驗
 * 
 * 3. btn click 前往測驗
 * 4. 是否已交卷
 *    a. 否 -> 3.
 * 
 * 5. btn click 測驗結果，是否為試聽課
 *    a. 是 -> 組試聽課測驗 url
 *    b. 否 -> 組正式課測驗 url
 */

export const Exam = () => {
  const { setAlert } = useAlert();
  const { course } = useContext(HomeSessionsTableNewContext);
  const { classId } = useParams();
  const [{ profile }] = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForBtns, setIsLoadingForBtns] = useState(new Array(course?.exams.length).fill(false));
  const [exams, setExams] = useState([]);
  const hasPapers = !!course?.exams.length;

  /**
   *  學生資料
   * @returns {object} {groupId, id, name, parentOneClubId}
   */
  const student = (() => {
    const { students } = course;
    const student = students.find(({ parentOneClubId }) => parentOneClubId === profile.id);
    return student;
  })();

  /* 取得該課程中的測驗 */
  const fetchExams = async () => {
    setIsLoading(true);
    const { data, status } = await getExamsApi(course.id);
    status === 'success'
      ? setExams(data)
      : setAlert(FETCH_EXAMS_ERR, 'error');
    setIsLoading(false);
  };

  /* 取得「前往測驗」 url */
  const getExamUrl = (exam, index) => {
    const { isAudition } = course;
    const { quizCode } = exam;
    const { id: seatNo, name: userName } = student;
    let url = `${EXAM_DOMAIN}/quiz/${quizCode}?userName=${userName}&seatNo=${seatNo}`;
    if(isAudition && index === 0) url += '&resultType=analysis';
    return url;
  };
  /* 取得「測驗結果」 url */
  const getExamResultUrl = (exam, index) => {
    const { isAudition } = course;
    const { quizCode, schoolYear } = exam;
    const { id: studentId, name: studentName } = student;
    const url = {
      audition: index === 0 ? 
      `${EXAM_DOMAIN}/${quizCode}/${schoolYear}/${studentId}/${studentName}/learnAnalysis` : 
      `${EXAM_DOMAIN}/${quizCode}/${schoolYear}/${studentId}/${studentName}`,
      formal: `${EXAM_DOMAIN}/${quizCode}/${schoolYear}/${studentId}/${studentName}`,
    };
    const result = isAudition
      ? url.audition
      : url.formal;
    return result;
  };
  /* 取得「測驗結果、前往測驗」url */
  const getUrl = (exam, index) => {
    // 是否已完成測驗
    const isFinishExam = exam.sumTime > 0 ? true : false;
    const url = isFinishExam
      ? getExamResultUrl(exam, index)
      : getExamUrl(exam, index);

    return url;
  };

  /* 建立測驗 */
  const createExam = async ({ course, paper }) => {
    const { examId: paperId, name } = paper;
    const organizationId = process.env.REACT_APP_LIVE_ORGANIZATION_ID;
    const { id: studentId } = student;
    const params = {
      paperId,
      // paperId: '111-f79639ea469d46f9af0680317eaa9e14',
      examName: name,
      service: SERVICE,
      paperType: PAPER_TYPE,
      courseId: course.id,
      organizationId,
      classId,
      studentId,
    };
    return await createExamApi(params);
  };
  /* 前往測驗 */
  const goToExam = async ({ paper, paperIndex }) => {
    setIsLoadingForBtns(prev => prev.map((btnBool, index) =>
      index === paperIndex ? true : btnBool,
    ));
    const { isSuccess, content } = await createExam({ course, paper });
    if (isSuccess) {
      const url = getExamUrl(content);
      const popup = window.open(url, '_blank');
      closeWindow({ popup, func: fetchExams });
    }
    setIsLoadingForBtns(prev => prev.map((btnBool, index) =>
      index === paperIndex ? false : btnBool,
    ));
  };

  /* 取得按鈕 onClick */
  const getBtnOnClick = ({ paper, exam, index: paperIndex }) => async () => {
    // 是否已建立測驗
    const isCreatedExam = !!exam;
    if (isCreatedExam) {
      const popup = window.open(getUrl(exam, paperIndex), '_blank');
      closeWindow({ popup, func: fetchExams });
    } else {
      await goToExam({ paper, paperIndex });
      await fetchExams();
    }
  };
  /* 取得按鈕顏色 */
  const getBtnColor = (exam) => {
    let color;
    // 是否已建立測驗
    const isCreatedExam = !!exam;
    if (isCreatedExam) {
      // 是否已完成測驗
      const isFinishExam = exam.sumTime > 0 ? true : false;
      color = isFinishExam
        ? 'standardSecondary'
        : 'new';

    } else {
      color = 'new';
    }

    return color;
  };
  /* 取得按鈕文字 */
  const getBtnText = (exam) => {
    let text;
    // 是否已建立測驗
    const isCreatedExam = !!exam;
    if (isCreatedExam) {
      // 是否已完成測驗
      const isFinishExam = exam.sumTime > 0 ? true : false;
      text = isFinishExam
        ? '測驗結果'
        : '前往測驗';

    } else {
      text = '前往測驗';
    }

    return text;
  };
  /* 試卷列表 */
  const papers = useMemo(() => {
    const { exams: papers } = course;
    const list = papers.map((paper, index) => {
      const { examId: paperId, id, name } = paper;
      const exam = exams.find(exam => exam.paperId === paperId);
      const btnText = getBtnText(exam);
      const btnColor = getBtnColor(exam);
      const btnOnClick = getBtnOnClick({ paper, exam, index });
      return {
        id,
        name,
        paperId,
        btnColor,
        btnText,
        btnOnClick,
      };
    });
    return list;
  }, [exams]);

  useEffect(() => {
    fetchExams();
  }, []);

  return (
    <UiRoot>
      {isLoading
        ? <Loading />
        : hasPapers
          ? papers.map(({ id, name, btnColor, btnOnClick, btnText }, index) =>
            <UiRow key={id}>
              <UiBox>
                <UiIconContainer>
                  <Icon.Svg
                    name="Homework"
                    color="#FFF"
                  />
                </UiIconContainer>
                <Tooltip
                  title={name}
                  lineClamp="2"
                >
                  <Typography>{name}</Typography>
                </Tooltip>
              </UiBox>
              <UiBox>
                <Button
                  buttonColor={btnColor}
                  onClick={btnOnClick}
                  loading={isLoadingForBtns[index]}
                >{btnText}</Button>
              </UiBox>
            </UiRow>)
          : <UiEmptyFigure>
            <UiEmptyImgContainer>
              <img alt="emptyImg" src={emptyImg} />
            </UiEmptyImgContainer>
            <UiEmptyFigcaption>{EMPTY_MSG}</UiEmptyFigcaption>
          </UiEmptyFigure>}
    </UiRoot>
  );
};
import React from 'react';
import qs from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { UiMatTabs,UiTab,UiTypography } from './Tab.style';

/**
 * 在這邊描述這個組件
 */

const a11yProps = index => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});


// eslint-disable-next-line react/prop-types
const TabPanel = ({ children, tabIndex, index, ...other }) => (
  <UiTypography
    component="div"
    role="tabpanel"
    hidden={tabIndex !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {tabIndex === index && children}
  </UiTypography>
);


export const Tab = ({
  data = [],
  getTabIndex = () => { },
}) => {
  const location = useLocation();
  const { tab: qsTab } = qs.parse(location.search);
  const { tab: paramTab } = useParams();
  const tab = qsTab || paramTab;
  const [{ tabIndex }, setState] = useSetState({ tabIndex: tab ? parseInt(tab) : 0 });

  const tabChangeHandler = (_, tabIndex) => {
    setState({ tabIndex });
    getTabIndex(tabIndex);
  };

  return (
    <>
      <UiMatTabs
        value={tabIndex}
        onChange={tabChangeHandler}
        indicatorColor="primary"
      >
        {
          data.map((item, index) => <UiTab key={index} label={item.label} {...a11yProps(index)} />)
        }
      </UiMatTabs>
      {
        data.map((item, index) => {
          const { label, Component } = item;
          return (
            <TabPanel
              key={index}
              label={label}
              index={index}
              tabIndex={tabIndex}
              {...a11yProps(index)}
            >
              <Component />
            </TabPanel>
          );
        })
      }
    </>
  );
};

Tab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      Component: PropTypes.elemennodetType
    })
  ),
  getTabIndex: PropTypes.func,
};



/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { useSetState } from 'utils/hooks/useSetState';
import { DAY } from 'constants/index';
import { Table, Select, IconInput, Button, Modal } from 'components';
import { useAlert } from 'utils/hooks/useAlert';
import { useAdmin } from 'store/admin';
import { emailReg } from 'utils/validation';

import {
  UiAuthorityTable,
  UiFilterBox,
  UiFilterBoxLeft,
} from './AuthorityTable.style';


/**
 * 付費會員列表
 */

const schema = {
  userId: {
    name: '帳號Id',
    defaultValue: '--'
  },
  userNickname: {
    name: '姓名',
    defaultValue: '--'
  },
  mobileNumber: {
    name: '連絡電話',
    defaultValue: '--'
  },
  email: {
    name: 'E-mail',
    defaultValue: '--'
  },
  openApplyDate: {
    name: '申請/開通日期',
    defaultValue: '--'
  },
  expiredDate: {
    name: '有效日期',
    defaultValue: '--'
  },
  status: {
    name: '狀態',
    defaultValue: '--'
  },
};

const PENDING = 'pending';
const TRIAL = 'trial';
const APPROVED = 'approved';
const EXPIRED = 'expired';
const stateShowText = [
  {
    id: 'status',
    state: {
      [PENDING]: '申請中',
      [TRIAL]: '體驗版',
      [APPROVED]: '已開通',
      [EXPIRED]: '已過期',
    }
  }
];

const selectOptions = [
  // {
  //   name: '帳號Id',
  //   value: 'userId'
  // },
  {
    name: '電子郵件',
    value: 'email'
  },
  {
    name: '手機號碼',
    value: 'mobileNumber'
  },
];

const modalText = {
  [PENDING]: '狀態提升為體驗版?',
  [TRIAL]: '狀態提升為已開通?',
  [APPROVED]: '開通期限延長30天?',
  [EXPIRED]: '狀態提升為已開通?',
};

export const AuthorityTable = () => {
  const [{ premiumUsers }, { getPremiumUsers, reviseUserPremiumStatus }] = useAdmin();
  const { setAlert } = useAlert();
  const [{
    searchType,
    searchValue,
    nowPage,
    rowsPage,
    isModalOpen,
    targetUserId,
    targetStatus,
  }, setState] = useSetState({
    searchType: '',
    searchValue: '',
    nowPage: 0,
    rowsPage: 10,
    isModalOpen: false,
    targetUserId: '',
    targetStatus: '',
  });

  const changePageRows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const chooseSearchType = searchType => {
    setState({
      searchType
    });
  };
  const search = value => {
    if (!searchType) {
      setAlert('尚未選擇標題!', 'error');
      return;
    }
    if (searchType === 'email' && !emailReg.test(value)) {
      setAlert('信箱格式錯誤!', 'error');
      return;
    }
    setState({
      searchValue: value
    });
    fetchApi(value);
  };

  const fetchApi = async value => {
    const payload = {
      nowPage,
      rowsPage,
      [searchType]: value ? value : searchValue
    };

    if (searchType) {
      payload[searchType] = value ? value : searchValue;
    }

    await getPremiumUsers(payload);
  };

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const modalButtons = [
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    },
    {
      text: '確定',
      func: async () => {
        const payload = {
          premiumDuration: DAY * 30
        };
        switch (targetStatus) {
          case PENDING:
            payload.premiumStatus = TRIAL;
            break;
          case TRIAL:
          case APPROVED:
          case EXPIRED:
            payload.premiumStatus = APPROVED;
            break;
          default:
            break;
        }
        await reviseUserPremiumStatus(targetUserId)(payload);
        await fetchApi();
      }
    }
  ];

  const reviseTextModal = params => {
    const { userId, status, lastAppliedAt, lastApprovedBy } = params;
    setState({
      isModalOpen: true,
      targetUserId: userId,
      targetStatus: status,
      targetTime: lastAppliedAt || lastApprovedBy
    });
  };

  const ActionComponents = ({ params }) => {
    switch (params.status) {
      case 'pending':
        return (
          <Button onClick={() => reviseTextModal(params)}>體驗</Button>
        );
      case 'trial':
        return (
          <Button onClick={() => reviseTextModal(params)}>開通</Button>
        );
      case 'approved':
        return (
          <Button onClick={() => reviseTextModal(params)}>延長</Button>
        );
      case 'expired':
        return (
          <Button onClick={() => reviseTextModal(params)}>開通</Button>
        );
      default:
        return (
          <div>這是BUG吧</div>
        );
    }
  };

  useEffect(() => {
    fetchApi();
  }, [nowPage, rowsPage]);

  return (
    <UiAuthorityTable>
      <UiFilterBox>
        <UiFilterBoxLeft>
          <Select
            label="搜尋類型"
            value={searchType}
            options={selectOptions}
            submitHandler={value => chooseSearchType(value)}
          />
          <IconInput value={searchValue} placeHolder="搜尋" onChange={value => search(value)} />
        </UiFilterBoxLeft>
      </UiFilterBox>
      <Table
        data={premiumUsers.data}
        schema={schema}
        changePage_Rows={changePageRows}
        stateShowText={stateShowText}
        totalPage={premiumUsers.total}
        nowPage={nowPage}
        ActionComponents={ActionComponents}
      />
      <Modal
        isOpen={isModalOpen}
        text={`是否要將<span>${targetUserId}</span>${modalText[targetStatus]}?`}
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
    </UiAuthorityTable>
  );
};

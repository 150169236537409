import styled from 'styled-components';

export const UiSessionsTableButtonBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	& > * + * {
		margin-top: 5px;
	}
`;
import { Api } from 'services/api';
import { stringify } from 'query-string';

/**
 * 取得新版課程列表
 * @param {object} params
 * @returns
 */
export const getCourse = async (groupId, params) => {
  const querystring = stringify(params);
  const endpoint = `${process.env.REACT_APP_ONECLUB_API_DOMAIN}/liveCourse/${groupId}?${querystring}`;
  const response = await Api.get(endpoint);
  return response;
};

/**
 * 取得新版課程列表(無班級)
 * @param {object} params
 * @returns
 */
export const getAllCourses = async (params) => {
  const querystring = stringify(params);
  const endpoint = `${process.env.REACT_APP_ONECLUB_API_DOMAIN}/liveCourse?${querystring}`;
  const response = await Api.get(endpoint);
  return response;
};

/**
 * 取得課程測驗
 * @param {object} params
 * @returns
 */
export const getExams = async (groupId) => {
  const endpoint = `${process.env.REACT_APP_ONECLUB_API_DOMAIN}/liveCourse/${groupId}/exams`;
  const response = await Api.get(endpoint);
  return response;
};

/**
 * 老師請假後，學生安排代課 / 安排調課 / 取消課程
 * @param {object} params
 * @returns
 */
export const updateOrCancelCourseAfterTeacherLeave = async (courseId, data) => {
  const endpoint = `${process.env.REACT_APP_ONECLUB_API_DOMAIN}/liveCourse/${courseId}/exception`;
  const response = await Api.post(endpoint, data);
  return response;
};

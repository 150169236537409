import React from 'react';
// import PropTypes from 'prop-types';
import {
  UiRoot,
  UiTitleWrapper,
  UiTitle,
  UiServicesWrapper,
  UiServiceWrapper,
  UiService,
  UiServiceFigure,
  UiServiceIntroContainer,
  UiServiceTitle,
  UiServiceSubtitle,
} from './HomeAllServices.style';
import { Button, Icon } from 'components';


/**
 * 學習全資源
 */

const TITLE = '學習全資源';
const BTN_TEXT = '前往';
const SERVICES = [
  {
    image: 'Study',
    title: 'OneStudy 線上學院',
    subtitle: '隨時隨地自學影片及測驗',
    link: 'https://onestudy.oneclass.com.tw/MainPage',
  },
  {
    image: 'BookReader',
    title: 'OneBook 智慧電子書',
    subtitle: '體驗豐富電子教科書資源',
    link: 'https://reader.oneclass.com.tw/bookstore',
  },
];

export const HomeAllServices = () => {
  return (
    <UiRoot>
      <UiTitleWrapper>
        <UiTitle>{TITLE}</UiTitle>
      </UiTitleWrapper>
  
      <UiServicesWrapper>
        {
          SERVICES.map(({ image, title, subtitle, link }) =>
            <UiServiceWrapper key={title}>
              <UiService>
                <UiServiceFigure>
                  <Icon.Svg color='#FFF' name={image} size='40px' />
                </UiServiceFigure>
                <UiServiceIntroContainer>
                  <UiServiceTitle>{title}</UiServiceTitle>
                  <UiServiceSubtitle>{subtitle}</UiServiceSubtitle>
                </UiServiceIntroContainer>
              </UiService>
              <Button
                buttonColor='standardSecondary'
                buttonPadding='8px 16px'
                iconSvg='Link'
                textColor='#121232'
                onClick={() => window.open(link, '_blank')}
              >{BTN_TEXT}</Button>
            </UiServiceWrapper>
          )
        }
      </UiServicesWrapper>
    </UiRoot>
  );
};

// HomeAllServices.propTypes = {

// };



import React from 'react';
import PropTypes from 'prop-types';
import { BsModal, Tab } from 'components';
import Exam from './components/Exam';
import Homework from './components/Homework';
import Resource from './components/Resource';

const TITLE = '課程資源';
const TABS = [
  { label: '測驗', Component: Exam },
  { label: '作業', Component: Homework },
  { label: '教材', Component: Resource },
];

export const ModalResource = ({
  open,
  close,
}) => {
  return (
    <BsModal
      open={open}
      onCancel={close}
      onClose={close}
      cancelDisplay={false}
      oKDisplay={false}
      title={TITLE}
    >
      <Tab data={TABS} />
    </BsModal>
  );
};

ModalResource.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Book from 'assets/images/defaultBook.png';
import { DashBoardCard, Loading } from 'components';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useUser } from 'store/user';
import { getMySessions } from 'services/api/home/sessions';
import { getAllCourses } from 'services/api/oneClub/course';
import { useServerTime } from 'utils/hooks/useServerTime';
import { UiSessionGroup } from './SessionGroup.style';

const TEACHER_STATUS = {
  REPLY: 'reply',
  ACCEPT: 'accept',
  REJECT: 'reject',
};
const INTERVAL ={ 
  NOW: 'NOW',
  PROCESSING: 'PROCESSING',
  PAST: 'PAST',
  FUTURE: 'FUTURE',
  ALL: 'ALL',
};

/**
 * 今日課程
 */

export const SessionGroup = ({ getSessionNum }) => {
  const history = useHistory();
  const [{ timestamp: serverTimestamp }, { getToday }] = useServerTime();
  const [{ profile }] = useUser();
  const [sessionsData, setSessionsData] = useState([]);
  const [newSessionsData, setNewSessionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const goSessionReader = item => {
    history.push(`/home/${item.groupId}/sessions?tab=${+!!item.isMMS}&interval=${INTERVAL.NOW}`);
  };

  const allSessionData = useMemo(() => {
    return [...sessionsData, ...newSessionsData];
  }, [sessionsData, newSessionsData]);

  const fetchSessions = async () => {
    setIsLoading(true);
    const todayStartAt = new Date(getToday('yyyy/MM/dd')).getTime();
    const todayEndAt = todayStartAt + (24 * 60 * 60 * 1000) - 1;
    const nowTime = serverTimestamp;
    const payload = {
      startAt: todayStartAt,
      endAt: todayEndAt,
      nowPage: 0,
      rowsPage: 100
    };
    const { data } = await getMySessions(payload);
    const sessions = data.sessions?.map(item => {
      let isActive = true;
      if (nowTime > item.endAt) isActive = false;
      const startAtText = format(item.startAt, 'HH:mm');
      const endAtText = format(item.endAt, 'HH:mm');
      const sessionInterval = `${startAtText} - ${endAtText}`;
      return {
        ...item,
        isActive,
        isMMS: false,
        sessionInterval
      };
    }) || [];
    setSessionsData(sessions);
    setIsLoading(false);
  };
  const fetchMMSSessions = async () => {
    setIsLoading(true);
    const todayStartAt = new Date(getToday('yyyy/MM/dd')).getTime();
    const todayEndAt = todayStartAt + (24 * 60 * 60 * 1000) - 1;
    const startAtIsoString = new Date(todayStartAt).toISOString();
    const endAtIsoString = new Date(todayEndAt).toISOString();
    const userOneClubId = profile.id;
    const params = {
      startAt: startAtIsoString,
      endAt: endAtIsoString,
      teacherStatus: TEACHER_STATUS.ACCEPT,
      parentOneClubId: userOneClubId,
    };

    const response = await getAllCourses(params);
    const { status, data } = response;
    if (status === 'success') {
      const sessions = data.courses
        .filter(item => item.status === 'publish' || item.status === 'finish')
        .map(item => {
          let isActive = true;
          if (item.status === 'finish') isActive = false;
          const groupId = item?.students.find(({ parentOneClubId }) => parentOneClubId === userOneClubId)?.groupId;
          const startAtText = format(new Date(item.startAt), 'HH:mm');
          const endAtText = format(new Date(item.endAt), 'HH:mm');
          const sessionInterval = `${startAtText} - ${endAtText}`;
          return {
            ...item,
            isMMS: true,
            hostName: item?.teacher?.nickName,
            groupName: item?.students.map(student => student.name).join('、'),
            groupId,
            isActive,
            sessionInterval
          };
        }) || [];
      setNewSessionsData(sessions);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!serverTimestamp) return;
    fetchSessions();
    fetchMMSSessions();
  }, [serverTimestamp]);

  useEffect(() => {
    getSessionNum(allSessionData?.length || 0);
  }, [allSessionData]);

  return (
    isLoading
      ? <Loading />
      : <UiSessionGroup>
        {
          allSessionData.map((item, index) => {
            return (
              <DashBoardCard
                key={index}
                title={item.name}
                groupName={item.groupName}
                hostName={item.hostName}
                isActive={item.isActive}
                isMMS={item.isMMS}
                sessionInterval={item.sessionInterval}
                defaultImg={Book}
                onClick={() => goSessionReader(item)}
              />
            );
          })
        }
      </UiSessionGroup>
  );
};

SessionGroup.propTypes = {
  getSessionNum: PropTypes.func,
};

import styled from 'styled-components';

export const UiRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiHeader = styled.div`
  color: #3A4052;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
`;

export const UiBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const UiButton = styled('div')(({ theme }) => ({
  '& svg': {
    fontSize: '2.2rem',
  },

  '& > button': {
    justifyContent: 'flex-start',
    minWidth: '100%',
    fontSize: '1.3rem',
    color: '#242C3F',
    background: '#FFF',
    border: '1px solid #D5D7DE',
    borderRadius: '16px',
    padding: '22px',
  },

  '&.transfer': {
    '& svg': {
      color: '#EC7963',
    },
  },

  '&.cancel': {
    '& svg': {
      color: '#A1A4B1',
    },
    '&:hover > button': {
      background: '#A1A4B1',
    },
  },

  '&:hover': {
    '& svg, & > button': {
      color: '#FFF',
    },
  },
}));
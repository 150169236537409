import React from 'react';
import PropTypes from 'prop-types';
import { UiRoot } from './ActionSessionStatus.style';
import { COURSE_INTERVAL_STATUS, COURSE_TYPE, LIVE_COURSE_STATUS } from '../HomeSessionsTableNew.constant';
import { Button } from 'components';
import { stringify } from 'query-string';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
// import { keepSessionReport } from 'services/api/organization/session';
// import { useAlert } from 'utils/hooks/useAlert';

export const ActionSessionStatus = ({ course, liveCourseStatus }) => {
  const { id, students, type, resources } = course;
  const [{ profile }] = useUser();
  const [{ myClasses }] = useClass();
  const { dataInfo: { userType } } = myClasses;
  // const { setAlert } = useAlert();

  /* click 觀看錄影 */
  const reviseVideoOnDemand = () => window.open(`https://oneboard-bs-dev.oneclass.com.tw/player/${id}`);

  /* 更新課堂報表 */
  // const fetchKeepSessionReport = async () => {
  //   const payload = { sessionId: id, timeSpanId };
  //   const { isSuccess } = await keepSessionReport(payload);
  //   if (!isSuccess) setAlert('取得課堂報表失敗!', 'error');
  // };

  /* 取得老師 or 學生姓名 */
  const getUserName = () => {
    const userOneClubId = profile.id;
    return userType === 'student'
      ? students.find(({ parentOneClubId }) => parentOneClubId === userOneClubId).name
      : profile?.nickname || `${profile?.lastName || ''}${profile?.firstName || ''}`;
  };

  /* click 進入教室 */
  const enterClass = () => {
    // const isCallReportApi = hostId === profile.id || users.some(id => id === profile.id);
    // if (isCallReportApi) fetchKeepSessionReport();
    const userName = getUserName();
    const bookIds = resources.map(({ bookId }) => bookId);
    const courseType = type === COURSE_TYPE.INDIVIDUAL_LIVE_COURSE ? 'sync-single' : 'sync-multiple';
    const userId = students.find(({ parentOneClubId }) => parentOneClubId === profile.id).parentOneClubId;
    const querystring = stringify({
      bookIds,
      autoRecord: false,
      classType: courseType,
      role: 'student',
      userName,
      userId,
      // userId: profile.id,  // 原 live
    },{ arrayFormat: 'index' });
    window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
  };

  const btn = {
    preparing: { text: '課程尚未開始', color: 'normal', disabled: true, },
    enteringClass: { text: '進入教室', color: 'normal', disabled: false, onClick: enterClass },
    over: { text: '課程結束', color: 'normal', disabled: true, },
    cancel: { text: '課程取消', color: 'normal', disabled: true, },
    issue: { text: '課程異動', color: 'normal', disabled: true, },
    recording: { text: '觀看錄影', color: 'new', disabled: false, onClick: reviseVideoOnDemand },
  };

  return (
    <UiRoot>
      {/* 準備中 */}
      {liveCourseStatus === LIVE_COURSE_STATUS.PREPARING &&
        <Button
          disabled={btn.preparing.disabled}
          buttonColor={btn.preparing.color}
          onClick={btn.preparing.onClick}
        >
          {btn.preparing.text}
        </Button>}

      {/* 準備中（課程開始前30分鐘）、進行中 */}
      {(liveCourseStatus === LIVE_COURSE_STATUS.PREPARING_AGO_30
      ||liveCourseStatus === LIVE_COURSE_STATUS.PROCESSING) &&
        <Button
          disabled={btn.enteringClass.disabled}
          buttonColor={btn.enteringClass.color}
          onClick={btn.enteringClass.onClick}
        >
          {btn.enteringClass.text}
        </Button>}

      {/* 已結束（課程結束後90分鐘內）、已完成（課程結束後90分鐘內 */}
      {(liveCourseStatus === LIVE_COURSE_STATUS.OVER_WITHIN_90
      ||liveCourseStatus === LIVE_COURSE_STATUS.FINISH_WITHIN_90) &&
        <>
          <Button
            disabled={btn.enteringClass.disabled}
            buttonColor={btn.enteringClass.color}
            onClick={btn.enteringClass.onClick}
          >
            {btn.enteringClass.text}
          </Button>
          <Button
            disabled={btn.recording.disabled}
            buttonColor={btn.recording.color}
            onClick={btn.recording.onClick}
          >
            {btn.recording.text}
          </Button>
        </>}

      {/* 已結束、已完成 */}
      {(liveCourseStatus === LIVE_COURSE_STATUS.OVER
      ||liveCourseStatus === LIVE_COURSE_STATUS.FINISH) &&
        <>
          <Button
            disabled={btn.over.disabled}
            buttonColor={btn.over.color}
            onClick={btn.over.onClick}
          >
            {btn.over.text}
          </Button>
          <Button
            disabled={btn.recording.disabled}
            buttonColor={btn.recording.color}
            onClick={btn.recording.onClick}
          >
            {btn.recording.text}
          </Button>
        </>}

      {/* 已取消 */}
      {liveCourseStatus === LIVE_COURSE_STATUS.CANCEL &&
        <Button
          disabled={btn.cancel.disabled}
          buttonColor={btn.cancel.color}
          onClick={btn.cancel.onClick}
        >
          {btn.cancel.text}
        </Button>}

      {/* 有異常 */}
      {liveCourseStatus === LIVE_COURSE_STATUS.ISSUE &&
        <Button
          disabled={btn.issue.disabled}
          buttonColor={btn.issue.color}
          onClick={btn.issue.onClick}
        >
          {btn.issue.text}
        </Button>}
    </UiRoot>
  );
};

ActionSessionStatus.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    intervalStatus: PropTypes.oneOf(COURSE_INTERVAL_STATUS),
    students: PropTypes.array,
    type: PropTypes.oneOf([COURSE_TYPE.INDIVIDUAL_LIVE_COURSE, COURSE_TYPE.GROUP_LIVE_COURSE]),
    resources: PropTypes.array,
  }),
  forestageCourseStatus: PropTypes.string,
  liveCourseStatus: PropTypes.string,
};

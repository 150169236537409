import React from 'react';
// import PropTypes from 'prop-types';
import { HomeGroupLayout, HomeAllServices } from 'components';


/**
 * 學習全資源 頁面
 */

export const HomeAllServicesPage = () => {
  return (
    <HomeGroupLayout>
      <HomeAllServices />
    </HomeGroupLayout>
  );
};

// HomeAllServicesPage.propTypes = {

// };

import React from 'react';
import PropTypes from 'prop-types';
import { UiSWOTItem } from './SWOTItem.style';
import { Icon } from 'components';

export const SWOTItem = ({ 
  title,
  icon,
  ml, 
  mt, 
  width,
  backgroundColor,
  children
 }) => {
  return (
    <UiSWOTItem ml={ml} mt={mt} width={width} backgroundColor={backgroundColor}>
      <Icon.Svg name={icon} />
      <div className='content'>
        <div className='title'>{title}</div>
        <div className='description'>{children}</div>
      </div>
    </UiSWOTItem>
  );
 };

SWOTItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  ml: PropTypes.string,
  mt: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';


export const UiBsModal = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 48px 24px 20px;
	width: 550px;
	min-height: ${({height})=> height || 'auto'};
	background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : '#fff'};
	border-radius: 12px;
	box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
	flex-direction: column;

	> .close {
		position: absolute;
		top: 29px;
		right: 29px;
		width: max-content;
		width: 24px;
		cursor: pointer;
		height: 24px;

		&:hover {
			opacity: 0.8;
		}
	}

	> .title {
		padding: 0 36px;
		font-size: 24px;
		color: #505565;
		margin-bottom: 16px;
		font-weight: 500;
	}

	> .content {
		padding: 0 36px;
		font-size: 14px;
		color: #505565;
		margin-bottom: auto;
		line-height: 32px;
	}

	> .buttonBox {
		display: flex;
		align-items: center;
		justify-content: ${({ btnPositionStyle }) => btnPositionStyle ? btnPositionStyle : 'flex-end'};
		margin-top: 56px;

		.button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 36px;
			border-radius: 2px;
			transition: 0.35s;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}

			&.white {
				margin-right: 10px;
				font-size: 16px;
				color: #454b5c;
				background-color: #e4e7ec;

				&:hover {
					background-color: #f2f4f8;
				}

				&:disabled {
					color: #d5d7de;
					background-color: #f2f4f8;
				}
			}

			&.black {
				font-size: 14px;
				color: #fff;
				background-color: #121232;
			}

			&.disabled {
				color: #d5d7de;
				background-color: #f2f4f8;
				cursor: not-allowed;
			}
		}
	}
`;

export const UiCircularProgress = styled(CircularProgress)`
	color: #fff;
`;

export const UiWhiteCircularProgress = styled(CircularProgress)`
	color: #8b90a0;
`;

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  chartOptions,
  options,
  COURSE_TYPE_MAPPING,
  ERR_MSG,
  ADVANTAGE_TEXT,
  CHANCE_TEXT,
  IMPROVEMENT_TEXT,
} from './HomeSessionFeedbackAudition.constant';
import {
  UiEmptyWrapper,
  UiEmptyImgContainer,
  UiEmptyMessage,
  UiActionWrapper,
  UiPreviousPageContainer,
  UiFeedbackTitle, 
  UiInformationPanel, 
  UiInfoIntro,
  UiDivider,
  UiInfoTrophy,
  UiInfoTrophyIntro,
  UiInfoTrophyIntroTitle,
  UiInfoTrophyIntroCount,
  UiInfoTrophyIcon,
  UiFeedbackTitleItem,
  UiFeedbackTitleItemLabel,
  UiSection,
  UiSectionTitle,
  UiSWOT,
  UiContent,
  UiChartBox,
  UiSWOTWrapper,
 } from './HomeSessionFeedbackAudition.style';
import sessionReportEmpty from 'assets/images/sessionReportEmpty.png';
import SWOTItem from './SWOTItem';
import { Icon, Loading } from 'components';
import { format, intervalToDuration } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import ReactApexCharts from 'react-apexcharts';
import { getUserRecords } from 'services/api/oneBoard';
import { getCourseFeedback } from 'services/api/oneClub/feedback';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { Typography } from '@material-ui/core';

// 英文轉分數
const convertEngToNum = (eng) => {
  return ([...options].reverse().findIndex(option => option.value === eng) + 1) * 20;
};
const transformChartData = (data) => data.map(eng => convertEngToNum(eng)); 
const formatDate = (date, pattern) => format(new Date(date), pattern, { locale: zhTW } );
const getDuration = (startAt, endAt) => {
  const { hours, minutes } = intervalToDuration({ start: new Date(startAt), end: new Date(endAt) });
  return hours * 60 + minutes;
};

export const HomeSessionFeedbackAudition = () => {
  const history = useHistory();
  const location = useLocation();
  const { setAlert } = useAlert();
  const { classId, sessionId } = useParams();
  const [{ profile }] = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [courseFeedbackOri, setCourseFeedbackOri] = useState(null);
  const [oneBoardRecordsOri, setOneBoardRecordsOri] = useState([]);

  const {
    id: courseId,
    teacher: { id: teacherId, firstName, lastName },
    students,
    name,
    type,
    startAt,
    endAt,
  } = location.state?.course;
  const infoPanelTitle = `${name}－課後回饋`;
  const courseType = `${COURSE_TYPE_MAPPING[type]}`;
  const teacherName = `${lastName}${firstName}`;

  const date = formatDate(startAt, 'yyyy/MM/dd（iiiii）');
  const startTime = formatDate(startAt, 'HH:mm');
  const endTime = formatDate(endAt, 'HH:mm');
  const duration = getDuration(startAt, endAt);
  const courseTime = `${date}${startTime}~${endTime}（${duration}分）`;

  const chartDatum = [
      courseFeedbackOri?.academic,
      courseFeedbackOri?.concentration,
      courseFeedbackOri?.understanding,
      courseFeedbackOri?.reaction,
      courseFeedbackOri?.participation];

  const advantageText = courseFeedbackOri?.advantage.map(item => ADVANTAGE_TEXT[item]).join('、');
  const chanceText = courseFeedbackOri?.chance.map(item => CHANCE_TEXT[item]).join('、');
  const improvementText = courseFeedbackOri?.improvement.map(item => IMPROVEMENT_TEXT[item]).join('、');

  const student = students.find(({ parentOneClubId }) => parentOneClubId === profile.id );
  const oneBoardRecord = oneBoardRecordsOri.find(({ userId }) => userId === student.id);

  const fetchCourseFeedback = async () => {
    const resp = await getCourseFeedback(teacherId, courseId);
    if (resp) {
      const { status, data } = resp;
      if (status === 'success') {
        setCourseFeedbackOri(data);
      }
    }
  };
  /* 取得 OneBoard 資料 */
  const fetchUserRecords = async () => {
    const params = { courseId: sessionId };
    const { data, status } = await getUserRecords(params);
    status === 'success'
      ? setOneBoardRecordsOri(data)
      : setAlert(ERR_MSG.fetchUserRecords, 'error');
  };
  const fetchData = async () => {
    await Promise.all([fetchCourseFeedback(), fetchUserRecords()]);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);


  const goBack = () => {
    const { nowPage, rowsPage } = location.state.table;
    const currLocation = {
      ...location,
      pathname: `/home/${classId}/sessions`,
      search: `?tab=1&nowPage=${nowPage}&rowsPage=${rowsPage}`,
    };
    history.push(currLocation);
  };

  return (
    <>
      <UiActionWrapper>
        <UiPreviousPageContainer onClick={() => goBack()}>
          <Icon title='返回上一頁' name='arrowBackIos' size='1.25rem' haveBg={false} icolor='#8B90A0' />
          返回上一頁
        </UiPreviousPageContainer>
      </UiActionWrapper>
      {isLoading
        ? <Loading />
        : !courseFeedbackOri
          ? <UiEmptyWrapper>
              <UiEmptyImgContainer>
                <img src={sessionReportEmpty} alt='無課堂報告' />
              </UiEmptyImgContainer>
              <UiEmptyMessage>
                尚無課後回饋
              </UiEmptyMessage>
            </UiEmptyWrapper>
          : <div>
              <UiInformationPanel>
                <UiInfoIntro>
                  <UiFeedbackTitle>{infoPanelTitle}</UiFeedbackTitle>
                  <UiFeedbackTitleItem>
                    <UiFeedbackTitleItemLabel>課程類型</UiFeedbackTitleItemLabel>｜{courseType}
                  </UiFeedbackTitleItem>
                  {/* <UiFeedbackTitleItem>
                    <UiFeedbackTitleItemLabel>授課科目</UiFeedbackTitleItemLabel>｜英語
                  </UiFeedbackTitleItem> */}
                  <UiFeedbackTitleItem>
                    <UiFeedbackTitleItemLabel>授課教師</UiFeedbackTitleItemLabel>｜{teacherName}
                  </UiFeedbackTitleItem>
                  <UiFeedbackTitleItem>
                    <UiFeedbackTitleItemLabel>課程時間</UiFeedbackTitleItemLabel>｜{courseTime}
                  </UiFeedbackTitleItem>
                </UiInfoIntro>
                <UiDivider />
                <UiInfoTrophy>
                  <UiInfoTrophyIntro>
                    <UiInfoTrophyIntroTitle>獎盃數</UiInfoTrophyIntroTitle>
                    <UiInfoTrophyIntroCount>{oneBoardRecord?.summary?.trophy}</UiInfoTrophyIntroCount>
                  </UiInfoTrophyIntro>
                  <UiInfoTrophyIcon>
                    <UiInfoTrophyIntroTitle>&nbsp;</UiInfoTrophyIntroTitle>
                    <Icon.Svg name="Trophy" haveBg={false} color="#F9C74F" size='50px' />
                  </UiInfoTrophyIcon>
                </UiInfoTrophy>
              </UiInformationPanel>
              <UiSection>
                <UiSectionTitle>學習表現</UiSectionTitle>
                <UiChartBox>
                  <ReactApexCharts
                    type="radar"
                    options={chartOptions}
                    series={[
                      {
                        name: 'series-1',
                        data: transformChartData(chartDatum),
                      },
                    ]}
                    width="100%"
                    height="100%"
                  />
                </UiChartBox>
              </UiSection>
              <UiSWOTWrapper>
                <UiSWOT className='advantage'>
                  <SWOTItem icon="Strength" title="優點" backgroundColor="#FFFAEE">
                    <Typography>{advantageText}</Typography>
                  </SWOTItem>
                </UiSWOT>
                <UiSWOT className='improvement'>
                  <SWOTItem icon="Weakness" title="待加強" backgroundColor="#FEF8F7">
                    <Typography>{improvementText}</Typography>
                  </SWOTItem>
                </UiSWOT>
                <UiSWOT className='chance'>
                  <SWOTItem icon="Opportunity" title="機會" backgroundColor="#EDF7FF">
                    <Typography>{chanceText}</Typography>
                  </SWOTItem>
                </UiSWOT>
              </UiSWOTWrapper>
              <UiSection>
                <UiSectionTitle>老師的話</UiSectionTitle>
                <UiContent>
                  {courseFeedbackOri.comment}
                </UiContent>
              </UiSection>
              <UiSection>
                <UiSectionTitle>學習重點</UiSectionTitle>
                <UiContent>
                  {courseFeedbackOri.recommend}
                </UiContent>
              </UiSection>
            </div>}
    </>
  );
};

// HomeSessionFeedback.propTypes = {
// };

// Strength,
// Weakness,
// Opportunity,
// Reward,
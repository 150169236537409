import { Api } from 'services/api';

/**
 * 學生請假 -> 調課
 * @param {object} data 
 * @param {string[]} courseIds
 * @param {string} reason
 * @param {string} studentId
 * @param {number[]} rescheduleTimes  // 取消課程，不需要
 * @param {boolean} arrangeAnotherTeacher // 取消課程，不需要
 * @returns 
 */
 export const updateOrCancelCourse = async (data) => {
  const endpoint = `${process.env.REACT_APP_ONECLUB_API_DOMAIN}/leave/student`;
  const response = await Api.post(endpoint, data);
  return response;
};
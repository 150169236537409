import React from 'react';

import { HomeGroupLayout, HomeSessionFeedbackAudition } from 'components';


/**
 *  課後回饋頁面
 */

export const HomeSessionFeedbackAuditionPage = props => {
  return (
    <HomeGroupLayout>
      <HomeSessionFeedbackAudition {...props} />
    </HomeGroupLayout>
  );
};


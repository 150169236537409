import styled from 'styled-components';
import { Chip } from '@material-ui/core';

export const UiTimeBox = styled.div`
  & > p:first-child {
    font-size: 1rem;
    font-weight: 700;
    color: #242C3F;
  }
`;

export const UiText = styled.span`
  font-size: 1rem;
`;

export const UiLabel = styled(Chip)(({ theme }) => ({
  borderRadius: '6px',
  background: '#EEF5F6',
  color: theme.palette.text.primary03,
  fontSize: '11px',
  marginLeft: '8px',
  height: '18px',

  '& > span': {
    padding: '0 6px',
  }
}));
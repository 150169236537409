import styled from 'styled-components';

export const UiRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UiHeader = styled.div`
  color: #3A4052;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  white-space: break-spaces;
`;

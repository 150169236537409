import React from 'react';

import { HomeGroupLayout, HomeSessionFeedback } from 'components';


/**
 *  課後回饋頁面
 */

export const HomeSessionFeedbackPage = props => {
  return (
    <HomeGroupLayout>
      <HomeSessionFeedback {...props} />
    </HomeGroupLayout>
  );
};


import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { UiRoot, UiHeader, UiBody, UiButton } from './InfoMode.style';

const HEADER_TEXT = '請選擇處理方式：';

export const InfoMode = ({ changePage }) => {
  return (
    <UiRoot>
      <UiHeader>{HEADER_TEXT}</UiHeader>
      <UiBody>
        <UiButton className='transfer'>
          <Button
            icon='reply'
            onClick={changePage(1)}
          >安排調課</Button>
        </UiButton>
        <UiButton className='cancel'>
          <Button
            icon='deleteForever'
            onClick={changePage(2)}
          >取消課程</Button>
        </UiButton>
      </UiBody>
    </UiRoot>
  );
};

InfoMode.propTypes = {
  changePage: PropTypes.func,
};
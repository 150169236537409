import React from 'react';
// import PropTypes from 'prop-types';
import {
  UiRoot,
  UiHeader,
} from './InfoSubstitute.style';

const HEADER_TEXT = '將由排課師安排合適教師';

export const InfoSubstitute = () => {
  return (
    <UiRoot>
      <UiHeader>{HEADER_TEXT}</UiHeader>
    </UiRoot>  
  );
};

// InfoSubstitute.propTypes = {
// };
import styled, { css } from 'styled-components';
import { Modal as MuiModal } from '@material-ui/core';

export const UiMuiCalendarModal = styled(MuiModal)`
  position: relative;
`;

export const UiClose = styled.div`
  position: absolute;
  top: 29px;
  right: 29px;
`;

export const UiModalContent = styled.div`
  min-width: 380px;
  background: #FFF;
  border-radius: 16px;
  padding: 60px 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 95%;
  /* width: ${({ isLessThanCalendarHeight }) => isLessThanCalendarHeight ? '700px' : '928px'};
  ${({ isPad }) => 
    isPad && css`
      width: 95vw;
    `
  }
  ${({ isLessThanCalendarHeight, isPad }) =>
    (isLessThanCalendarHeight && isPad) && css`
      width: 80vw;
    `
  } */
`;

export const UiHeader = styled.div`

`;

export const UiTitle = styled.div`
  color: #242C3F;
  font-size: 1.6rem;
  margin-bottom: 24px;
`;

export const UiCategoryWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const UiCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UiCategoryColor = styled.div`
  height: 10px;
  width: 10px;
  background: ${({ background }) => background };
  border-radius: 50%;
`;

export const UiCategoryName = styled.div`
  color: #212B36;
  font-size: 1rem;
`;

export const UiBody = styled.div`

`;

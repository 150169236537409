import React from 'react';
import { UiAlert } from './AlertMessage.style';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';

export const AlertMessage = ({ open = true, msg, icon, close }) => {
  return (
    <Collapse in={open}>
      <UiAlert
        icon={icon}
        action={
          <Icon
            name='close'
            icolor='#242C3F'
            size='24px'
            haveBg={false}
            onClick={close}
          />
        }
      >
        {msg}
      </UiAlert>
    </Collapse>
  );
};

AlertMessage.propTypes = {
  open: PropTypes.bool,
  msg: PropTypes.string,
  icon: PropTypes.node,
  close: PropTypes.func,
};
export const CHART_STATE_MAP = {
  academic: '學科力',
  concentration: '專心度',
  understanding: '理解力',
  reaction: '反應力',
  participation: '參與度',
};

export const chartOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [
      CHART_STATE_MAP.academic,
      CHART_STATE_MAP.concentration,
      CHART_STATE_MAP.understanding,
      CHART_STATE_MAP.reaction,
      CHART_STATE_MAP.participation,
    ],
  },
  yaxis: {
    tickAmount: 5,
    max: 100,
    min: 0,
  },
  fill: {
    opacity: 0.24,
    colors: ['#3366FF'],
  },
  plotOptions: {
    radar: {
      polygons: {
        strokeColor: '#DFE3E8',
        fill: {
          colors: ['#F4F6F8', '#fff'],
        },
      },
    },
  },
};

export const options = [
  {
    label: '超棒',
    value: 'a',
  },
  {
    label: '優良',
    value: 'b',
  },
  {
    label: '好',
    value: 'c',
  },
  {
    label: '可加強',
    value: 'd',
  },
  {
    label: '需改善',
    value: 'e',
  },
];

export const COURSE_TYPE_MAPPING = {
  individualLiveCourse: '家教個人課',
  groupLiveCourse: '家教團體課',
};

export const ERR_MSG = {
  fetchUserRecords: '無法取得 OneBoard 資料',
};
import React from 'react';
import PropTypes from 'prop-types';
import {
  UiRoot,
  UiHeader,
  UiBody,
  UiSection,
  UiItem,
  UiSubtitle,
  UiDateTimePicker,
} from './InfoTransfer.style';
import { Checkbox } from 'components';
import { formatISO, intervalToDuration } from 'date-fns';

export const InfoTransfer = ({ formData, changeFormData, helperTexts, course }) => {
  const { startAt, endAt } = course;
  const getDuration = (startAt, endAt) => {
    const { hours, minutes } = intervalToDuration({ start: new Date(startAt), end: new Date(endAt) });
    return hours * 60 + minutes;
  };
  const duration = getDuration(startAt, endAt);
  const headerText = `請填寫三個可以調課的時間\r\n課程時長：${duration}分鐘`;

  return (
    <UiRoot>
      <UiHeader>{headerText}</UiHeader>
      <UiBody>
        <UiSection>
          <UiItem mr='16px'>
            <UiSubtitle>順位一</UiSubtitle>
            <UiDateTimePicker
              value={formData.date_1}
              onChange={value => changeFormData('date_1')(formatISO(new Date(value).setSeconds(0, 0)))}
              error={!!helperTexts.date_1}
              helperText={helperTexts.date_1}
            />
          </UiItem>
          <UiItem mr='16px'>
            <UiSubtitle>順位二</UiSubtitle>
            <UiDateTimePicker
              value={formData.date_2}
              onChange={value => changeFormData('date_2')(formatISO(new Date(value).setSeconds(0, 0)))}
              error={!!helperTexts.date_2}
              helperText={helperTexts.date_2}
            />
          </UiItem>
          <UiItem mr='16px'>
            <UiSubtitle>順位三</UiSubtitle>
            <UiDateTimePicker
              value={formData.date_3}
              onChange={value => changeFormData('date_3')(formatISO(new Date(value).setSeconds(0, 0)))}
              error={!!helperTexts.date_3}
              helperText={helperTexts.date_3}
             />
          </UiItem>
          <UiItem>
            <Checkbox
              name='isSubstituteTeacher'
              label="若原教師時間無法配合，可以接受代課教師"
              checkedValue={true}
              checked={formData.isSubstituteTeacher}
              onChange={value => changeFormData('isSubstituteTeacher')(!!value)}
            />
          </UiItem>
        </UiSection>
      </UiBody>
    </UiRoot>
  );
};

InfoTransfer.propTypes = {
  formData: PropTypes.object,
  changeFormData: PropTypes.func,
  helperTexts: PropTypes.object,
  course: PropTypes.object,
};
import React from 'react';
import PropTypes from 'prop-types';
import { getDurationText, getEduSubjectsText, getGradesText, getIsProcessing } from 'helper/openCourse';
import {
  UiBody,
  // UiBodyTitle, // 學制科目
  UiBodySubtitle,
  UiBookContainer,
  UiBook,
  UiDuration,
  UiHeader,
  UiHeaderDetail,
  UiHeaderTitle,
  // UiHeaderSubtitle,  // 年級
  UiProcessing,
  UiProcessingImg,
  UiProcessingFigcaption,
  UiSessionCard,
} from './SessionCard.style';

const processingFigcaption = '進行中';

export const SessionCard = (props) => {
  const { onClick, session } = props;
  const { title } = session;
  const durationText = getDurationText(session);
  const gradesText = getGradesText(session);
  const eduSubjectsText = getEduSubjectsText(session);
  const isProcessing = getIsProcessing(session);
  const newSession = { ...session, eduSubjectsText, gradesText, durationText };

  return (
    <UiSessionCard onClick={()=>onClick(newSession)}>
      <UiHeader>
        <UiBookContainer>
          <UiBook />
        </UiBookContainer>
        <UiHeaderDetail>
          <UiHeaderTitle>{title}</UiHeaderTitle>
          {/* <UiHeaderSubtitle>{gradesText}</UiHeaderSubtitle> */}
        </UiHeaderDetail>
      </UiHeader>

      <UiBody>
        {/* <UiBodyTitle>{eduSubjectsText}</UiBodyTitle> */}
        <UiBodySubtitle>
          <UiDuration>{durationText}</UiDuration>
          {
            isProcessing &&
              <UiProcessing>
                <UiProcessingImg />
                <UiProcessingFigcaption>{processingFigcaption}</UiProcessingFigcaption>
              </UiProcessing>
          }
        </UiBodySubtitle>
      </UiBody>
    </UiSessionCard>
  );
};


SessionCard.propTypes = {
  onClick: PropTypes.func,
  session: PropTypes.object,
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InfoMode } from './InfoMode/InfoMode';
import { InfoTransfer } from './InfoTransfer/InfoTransfer';
import { InfoCancel } from './InfoCancel/InfoCancel';
import { ALERT_MSG } from '../HomeSessionsTableNew.constant';
import { BsModal } from 'components';
import { updateOrCancelCourse as updateOrCancelCourseApi } from 'services/api/oneClub/leave';
import { useAlert } from 'utils/hooks/useAlert';


const FORM_TITLES = ['我要請假', '安排調課', '取消課程'];
const INITIAL_HELPER_TEXT = {
  date_1: null,
  date_2: null, 
  date_3: null,
};
const INITIAL_FORM_DATA = {
  date_1: null,
  date_2: null, 
  date_3: null,
  isSubstituteTeacher: false,
  reason: null,
};


export const ModalLeaveStudent = ({ open, close, course, fetchCourse }) => {
  const { id, students } = course;
  const { setAlert } = useAlert();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [helperTexts, setHelperTexts] = useState(INITIAL_HELPER_TEXT);

  const isFormEmpty = (() => {
    if (page === 1) return (!formData.date_1 || !formData.date_2 || !formData.date_3 || !formData.reason);
    else if (page === 2) return (!formData.reason);
    else return false;
  })();

  const changePage = (page) => () => setPage(page);
  const changeFormData = (key) => (value) => setFormData(prev => ({ ...prev, [key]: value }));
  const resetFormData = () => setFormData({ ...INITIAL_FORM_DATA });

  const pageDisplay = () => {
    if (page === 0) return <InfoMode changePage={changePage} />;
    else if (page === 1) {
      return <InfoTransfer
        formData={formData}
        changeFormData={changeFormData}
        helperTexts={helperTexts}
        course={course}
      />;
    }
    else if (page === 2) {
      return <InfoCancel
        formData={formData}
        changeFormData={changeFormData}
        course={course}
      />;
    }
  };

  const validateForm = () => {
    if (page === 1) {
      // 檢查日期欄位是否相法
      const requiredFields = [
        { date_1: formData.date_1 },
        { date_2: formData.date_2 },
        { date_3: formData.date_3 },
      ];
      const newHelperTexts = requiredFields.reduce((prev, currField) => {
        const checkedFields = requiredFields.filter(field => field !== currField && field);
        const checkedValues = checkedFields.map(field => Object.values(field)[0]);
        const currKey = Object.keys(currField)[0];
        const isSame = checkedValues.includes(currField[currKey]);
        isSame
          ? prev = { ...prev, [currKey]: '日期時間不可重複' }
          : prev = { ...prev, [currKey]: null };
        return prev;
      }, {});
      setHelperTexts(newHelperTexts);
      const isError = Object.values(newHelperTexts).some(text => !!text);
      return isError;
    }
  };
  const clickOkForTransfer = async () => {
    const isError = validateForm();
    if (isError) return;
    setIsLoading(true);
    const params = {
      courseIds: [ id ],
      reason: formData.reason,
      studentId: students[0].id,
      rescheduleTimes: [formData.date_1, formData.date_2, formData.date_3],
      arrangeAnotherTeacher: formData.isSubstituteTeacher,
    };
    const { status } = await updateOrCancelCourseApi(params);
    if (status === 'success') {
      setAlert(ALERT_MSG.TRANSFER.SUCCESS, 'success');
      close();
      fetchCourse();
    }
    else if (status === 'failure') {
      setAlert(ALERT_MSG.TRANSFER.ERROR, 'error');
    }
    setIsLoading(false);
  };

  const clickOkForCancel = async () => {
    setIsLoading(true);
    const params = {
      courseIds: [ id ],
      reason: formData.reason,
      studentId: students[0].id,
    };
    const { status } = await updateOrCancelCourseApi(params);
    if (status === 'success') {
      setAlert(ALERT_MSG.CANCEL.SUCCESS, 'success');
      close();
      fetchCourse();
    }
    else if (status === 'failure') {
      setAlert(ALERT_MSG.CANCEL.ERROR, 'error');
    }
    setIsLoading(false);
  };

  const okHandler = () => {
    if (page === 1) clickOkForTransfer();
    else if (page === 2) clickOkForCancel();
  };

  const cancelHandler = () => {
    if (page === 0) close();
    else if (page === 1) setPage(0);
    else if (page === 2) setPage(0);
  };

  useEffect(() => {
    resetFormData();
  }, [page]);

  return (
    <BsModal
      isLoading={isLoading}
      open={open}
      onCancel={cancelHandler}
      onClose={close}
      onOk={okHandler}
      oKDisplay={!!page}
      okDisabled={isFormEmpty}
      okText='送出'
      cancelDisplay={!!page}
      title={FORM_TITLES[page]}
    >
      {pageDisplay()}
    </BsModal>
  );
};

ModalLeaveStudent.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  course: PropTypes.object,
  fetchCourse: PropTypes.func,
};
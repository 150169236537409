/* eslint-disable max-len */
import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import ActionSessionStatus from './ActionSessionStatus';
import ActionOther from './ActionOther';
import { UiLabel, UiText, UiTimeBox } from './HomeSessionsTableNew.style';
import {
  COURSE_INTERVAL_STATUS,
  TEACHER_STATUS,
  LIVE_COURSE_STATUS,
  LIVE_REQUEST_ISSUES,
} from './HomeSessionsTableNew.constant';
import { HomeSessionsTableNewContext } from './HomeSessionsTableNew.context';
import { HomeSessionsFilter, Loading, Table, Prompt } from 'components';
import { MINUTE } from 'constants/index';
import { format, intervalToDuration } from 'date-fns';
import qs from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getCourse as getCourseApi } from 'services/api/oneClub/course';
// import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { useServerTime } from 'utils/hooks/useServerTime';
import { useInterval } from 'utils/hooks/useInterval';


/**
 * 前台課程表（新版）
 */

// const tableData = [
//   {
//     // name: '籃球課',
//     name: <UiLabel label='試聽'/>,
//     subject: '體育',
//     time: <UiTimeBox><p>2021-05-25</p><p>09:00-18:00 (200分)</p></UiTimeBox>,
//     hostName: '徐教師',
//     sessionStatus: <ActionSessionStatus />,
//     actionComponents: <ActionOther />,
//   },
//   {
//     name: '羽球課',
//     subject: '體育',
//     time: <UiTimeBox><p>2022-06-30</p><p>09:00-18:00 (200分)</p></UiTimeBox>,
//     hostName: '陳教師',
//     sessionStatus: <ActionSessionStatus />,
//     actionComponents: <ActionOther />,
//   },
// ];

const schema = {
  name: { name: '課程名稱', defaultValue: '' },
  subjects: { name: '科目', defaultValue: '--' },
  date: { name: '時間', defaultValue: '' },
  hostName: { name: '教師', defaultValue: '' },
  sessionStatus: { name: '開始上課', defaultValue: '' },
  actionComponents: { name: '', defaultValue: '' },
};
const INTERVAL ={ 
  NOW: 'NOW',
  PROCESSING: 'PROCESSING',
  PAST: 'PAST',
  FUTURE: 'FUTURE',
  ALL: 'ALL',
};

export const HomeSessionsTableNew = () => {
  const history = useHistory();
  const location = useLocation();
  const { nowPage, rowsPage, interval=INTERVAL.ALL } = qs.parse(location.search);
  const { setAlert } = useAlert();
  const { classId } = useParams();
  // const [{ subjects: { data: subjectsOri } }] = useClass();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const [isLoading, setIsLoading] = useState(true);
  const [courseOri, setCourseOri] = useState(null);
  const [filterData, setFilterData] = useState({
    name: '',
    dateInterval: interval,
    startAt: null,
    endAt: null,
  });
  const [table, setTable] = useState({
    nowPage: parseInt(nowPage) || 0,
    rowsPage: parseInt(rowsPage) || 10,
  });

  const fetchCourse = useCallback(async (nowPage = table.nowPage, rowsPage = table.rowsPage, filterParams = filterData) => {
    const startAt = filterParams?.startAt;
    const endAt = filterParams?.endAt;
    const filter = {
      ...filterParams,
      startAt: startAt ? new Date(startAt).toISOString() : new Date(0).toISOString(),
      endAt: endAt ? new Date(endAt).toISOString() : undefined,
    };
    setIsLoading(true);
    const params = {
      skip: nowPage * rowsPage,
      limit: rowsPage,
      isNotReject: true,
      startAt: filter.startAt,
      endAt: filter.endAt,
      name: filter.name,
    };
    const { isSuccess, data } = await getCourseApi(classId, params);
    if (isSuccess) setCourseOri(data);
    else setAlert('取得資料失敗', 'error');
    setIsLoading(false);
  }, [filterData, table]);
  useInterval(fetchCourse, MINUTE);

  const formatHostName = (teacher, teacherStatus) => teacherStatus === TEACHER_STATUS.ACCEPT ? `${teacher?.lastName}${teacher?.firstName}` : '';
  const formatDate = (date, pattern) => format(new Date(date), pattern);
  const getDuration = (startAt, endAt) => {
    const { hours, minutes } = intervalToDuration({ start: new Date(startAt), end: new Date(endAt) });
    return hours * 60 + minutes;
  };
  // const getSubjects = (tags) => {
  //   const subjectCodes = tags.map(tag => tag.split('-')[1]);
  //   const subjects = subjectCodes.reduce((prev, curr) => {
  //     const subject = subjectsOri.find(({ value }) => value === curr);
  //     !prev.includes(subject.name) && prev.push(subject.name);
  //     return prev;
  //   }, []);
  //   return subjects;
  // };

  /**
   * 取得課程狀態，取消 -> 有異常 -> 無異常（一般課程狀態判斷）
   * @param {any} course 
   * @returns {string} LIVE_COURSE_STATUS
   */
  const getLiveCourseStatus = (course) => {
    const { intervalStatus, issueGroup, startAt, endAt } = course;
    const isRequestIssues = issueGroup.some(issue => LIVE_REQUEST_ISSUES.includes(issue));
    switch (intervalStatus) {
      // 已取消
      case COURSE_INTERVAL_STATUS.CANCEL:
        return LIVE_COURSE_STATUS.CANCEL;
      // 結束，且課程完成
      case COURSE_INTERVAL_STATUS.FINISH:
        if (isRequestIssues) return LIVE_COURSE_STATUS.ISSUE;
        if (serverTimestamp < new Date(endAt).getTime() + 5400000) return LIVE_COURSE_STATUS.FINISH_WITHIN_90;
        return LIVE_COURSE_STATUS.FINISH;
      // 已結束
      case COURSE_INTERVAL_STATUS.OVER:
        if (isRequestIssues) return LIVE_COURSE_STATUS.ISSUE;
        if (serverTimestamp < new Date(endAt).getTime() + 90 * MINUTE) return LIVE_COURSE_STATUS.OVER_WITHIN_90;
        return LIVE_COURSE_STATUS.OVER;
      // 進行中
      case COURSE_INTERVAL_STATUS.PROCESSING:
        if (isRequestIssues) return LIVE_COURSE_STATUS.ISSUE;
        return LIVE_COURSE_STATUS.PROCESSING;
      // 準備中
      case COURSE_INTERVAL_STATUS.PREPARING:
      default:
        if (isRequestIssues) return LIVE_COURSE_STATUS.ISSUE;
        if (serverTimestamp > new Date(startAt).getTime() - 30 * MINUTE) return LIVE_COURSE_STATUS.PREPARING_AGO_30;
        return LIVE_COURSE_STATUS.PREPARING;
    }
  };
  const tableData = useMemo(() => {
    if (!courseOri) return [];
    const data = courseOri.courses.map(course => {
      const { name, isAudition, startAt, endAt, teacher, teacherStatus } = course;
      const hostName = formatHostName(teacher, teacherStatus);
      const date = formatDate(startAt, 'yyyy-MM-dd');
      const startTime = formatDate(startAt, 'HH:mm');
      const endTime = formatDate(endAt, 'HH:mm');
      const duration = getDuration(startAt, endAt);
      // const subjects = getSubjects(tags);
      const liveCourseStatus = getLiveCourseStatus(course);
      return {
        name: <UiText>{name}{isAudition && <UiLabel label='試聽' />}</UiText>,
        hostName: <Typography>{hostName}</Typography>,
        date: <UiTimeBox><p>{date}</p><p>{startTime}-{endTime} ({duration}分)</p></UiTimeBox>,
        // subjects: subjects.join('、'),
        sessionStatus: (
          <ActionSessionStatus
            course={course}
            liveCourseStatus={liveCourseStatus}
          />
        ),
        actionComponents: (
          <HomeSessionsTableNewContext.Provider
            value={{ course }}
          >
            <ActionOther
              course={course}
              fetchCourse={fetchCourse}
              liveCourseStatus={liveCourseStatus}
              table={table}
            />
          </HomeSessionsTableNewContext.Provider>
        ),
      };
    });
    return data;
  }, [courseOri]);


  const changeFilterData = (changedData) => {
    const nowPage = 0;
    const filterParams = {
      ...filterData,
      ...changedData,
    };
    setFilterData(prev => ({ ...prev, ...changedData }));
    setTable(prev => ({ ...prev, nowPage }));
    history.push({ search: `?tab=1&nowPage=${nowPage}&rowsPage=${table.rowsPage}&interval=${changedData.dateInterval}` });
    fetchCourse(nowPage, undefined, filterParams);
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setTable(prev => ({ ...prev, nowPage: newPage, rowsPage: newRowsPage }));
    history.push({ search: `?tab=1&nowPage=${newPage}&rowsPage=${newRowsPage}&interval=${filterData.dateInterval}` });
    fetchCourse(newPage, rowsPage);
  };

  return (
    <>
      <HomeSessionsFilter
        filterData={filterData}
        changeFilterData={changeFilterData} />
      {isLoading && <Loading />}
      {courseOri?.total === 0
        ? <Prompt message="目前暫無課程"></Prompt>
        : <Table
            schema={schema}
            data={tableData}
            totalPage={courseOri?.total}
            changePage_Rows={changePage_Rows}
            nowPage={table.nowPage}
          />}
    </>
  );
};

import { format } from 'date-fns';
import { zhTW } from 'date-fns/locale';

const eduObj = {
  E: '國小',
  J: '國中',
  H: '高中',
};

const gradesObj = {
  G01: '小一',
  G02: '小二',
  G03: '小三',
  G04: '小四',
  G05: '小五',
  G06: '小六',
  G07: '國一',
  G08: '國二',
  G09: '國三',
  G10: '高一',
  G11: '高二',
  G12: '高三',
};

const subjectsObj = {
  CH: '國語',
  CN: '華語',
  EN: '英語',
  MA: '數學',
  NA: '自然與生活科技',
  SO: '社會',
  LI: '生活',
  PE: '健康與體育',
  PC: '國文',
  BI: '生物',
  PY: '理化',
  EA: '地球科學',
  GE: '地理',
  HI: '歷史',
  CT: '公民',
  CO: '綜合活動',
  TC: '科技',
  EW: '英文',
  PH: '物理',
  CE: '化學',
  CS: '公民與社會'
};

export const getDurationText = (session) => {
  const { endAt, startAt } = session;
  return `${format(startAt, 'HH:mm', { locale: zhTW })}-${format(endAt, 'HH:mm')}`;
};

export const getGradesText = (session) => {
  const { grades } = session;
  return grades.map(grade => gradesObj[grade]).join('、');
};

export const getEduSubjectsText = (session) => {
  const { subjects } = session;

  /**
   * 將 subjects 依照國小、國中、高中排序
   */
  const newSubjects = Object.keys(subjects)
    .sort((next, prev) => {
      const educations = Object.keys(eduObj);
      const nextIndex = educations.findIndex(item => item === next);
      const prevIndex = educations.findIndex(item => item === prev);
      return nextIndex > prevIndex ? 1 : -1;
    })
    .reduce((obj, key) => {
      obj[key] = subjects[key];
      return obj;
    }, {});

  /**
   * 中文名之 eduSubjects 物件陣列
   * ex. [{ 國小: ['國語', '華語'], 國中: ['英語', '數學'], 高中: ['物理', '化學']}]
   */
  const newEduSubjects = Object.entries(newSubjects).reduce((prev, curr) => {
    const edu = curr[0];
    const subjects = curr[1];
    if (!subjects.length) {
      return prev;
    } else {
      const eduName = eduObj[edu];
      const subjectsNameList = subjects.map(subject => subjectsObj[subject]);
      prev.push({ [eduName]: subjectsNameList });
      return prev;
    }
  }, []);

  /**
   * eduSubjectText 之陣列
   * ex. [["國小國語,華語"], ["國中英文,數學"], ["高中物理、化學"]]
   */
  const eduSubjectsTextList = newEduSubjects.reduce((prev, curr) => {
    const eduSubjectsText = `${Object.keys(curr)[0]}${Object.values(curr)[0].join('、')}`;
    prev.push(eduSubjectsText);
    return prev;
  }, []);

  const eduSubjectsText = eduSubjectsTextList.join(' / ');

  return eduSubjectsText;
};

export const getIsProcessing = (session) => {
  const { endAt, startAt } = session;
  const now = Date.now();
  const isProcessing = now > startAt && now < endAt;
  return isProcessing;
};

export const getIsIncludesClassGrades = (classGrades, grades) => {
  const isIncludesClassGrades = grades.some(grade => classGrades.includes(grade));
  return isIncludesClassGrades;
};
import React from 'react';
import PropTypes from 'prop-types';
import rocket from 'assets/images/modal_welcome_rocket.svg';
import { Button } from 'components';
import {
  UiRoot,
  UiTitle,
  UiIntro,
  UiStartWrapper,
  UiRocketContainer,
  UiRocketImg,
} from './SessionIntroduction.style';

const title = '歡迎來到 OneClass\r\n開始您的學習旅程！';
const intro = '在這裡你將可以在老師們的陪伴下學習，線上上課、繳交作業，即時掌握學習進度，一起展開豐富有趣的學習旅程！';
const btnText = '立即開始';

export const SessionIntroduction = ({ onClose }) => {
  return (
    <UiRoot>
      <UiTitle>{title}</UiTitle>
      <UiIntro>{intro}</UiIntro>
      <UiStartWrapper>
        <Button
          borderRadius='41px'
          buttonColor='new'
          buttonPadding='8px 13px 8px 24px'
          endIcon='arrowForward'
          onClick={onClose}
        >{btnText}</Button>
        <UiRocketContainer>
          <UiRocketImg src={rocket}/>
        </UiRocketContainer>
      </UiStartWrapper>
    </UiRoot>
  );
};

SessionIntroduction.propTypes = {
  onClose: PropTypes.func,
};

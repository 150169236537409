import React from 'react';
import PropTypes from 'prop-types';
import {
  UiRoot,
  UiHeader,
  UiBody,
  UiSection,
  UiItem,
  UiTextArea,
} from './InfoCancel.style';

const HEADER_TEXT = '將退還此課程堂數。';

export const InfoCancel = ({ formData, changeFormData }) => {
  return (
    <UiRoot>
      <UiHeader>{HEADER_TEXT}</UiHeader>
      <UiBody>
        <UiSection>
          <UiItem flexDirection='column' alignItems='flex-start'>
            <UiTextArea
              fullWidth
              multiline
              rows={4}
              label='請輸入請假原因*'
              variant='outlined'
              value={formData.reason}
              onChange={e => changeFormData('reason')(e.target.value)}
            />
          </UiItem>
        </UiSection>
      </UiBody>
    </UiRoot>
  );
};

InfoCancel.propTypes = {
  formData: PropTypes.object,
  changeFormData: PropTypes.func,
};
/* eslint-disable max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, OutlineButton } from 'components';
import { stringify } from 'query-string';
import { ORGANIZATION_SETTINGS_VALUE, EXAM_PROJECT_NAME, MINUTE } from 'constants/index';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { keepSessionReport } from 'services/api/organization/session';
import { useClass } from 'store/class';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { getPageName } from 'utils/pagePathSlicer';
import { UiSessionsTableButtonBox } from './ActionComponents.style';

export const ActionComponents = ({
  params,
  isClick,
  nowPage,
  sessionAttendanceManagementSetting,
  setState,
  serverTimestamp,
}) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const { pathname } = useLocation();
  const { classId } = useParams();
  const [{ myOrganization, profile }] = useUser();
  const [, { attendanceSession }] = useSessions();
  const [{ myClasses }] = useClass();
  const { dataInfo: { name: className, userType } } = myClasses;
  
  const {
    isCanEnterClass: { type },
    resources,
    id,
    timeSpanId,
    hostId,
    hostName,
    hostAttendance,
    preExamId,
    postExamId,
    type: sessionsType,
    users,
    startAt,
   } = params;
  const usersLength = users?.length || 0;

  const isTrial = useMemo(() => {
    if (!className) return;
    return className.includes('#');
  }, [className]);

  // 更新課堂報表
  const fetchKeepSessionReport = async () => {
    const payload = {
      sessionId: id,
      timeSpanId,
    };
    const { isSuccess } = await keepSessionReport(payload);
    if (!isSuccess) {
      setAlert('取得課堂報表失敗!', 'error');
    } 
  };

  const enterInteractiveSession = () => {
    const isCallReportApi = hostId === profile.id || users.some(id => id === profile.id);
    if (isCallReportApi) {
      fetchKeepSessionReport();
    }

    let classType = '';
    let userName = '';
    switch(sessionsType){
      case 'interactive':
        classType = usersLength <= 1 ? 'single' : 'group';
        break;
      case 'collaborative':
        classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
        break;
      case 'video':
        classType = 'group';
        break;
      default :
        break;
    }

    if (userType === 'student') {
      if (isTrial) {
        userName = className;
      } else {
        userName = myOrganization.userProfile.nickname;
      }
    } else {
      userName = profile?.nickname || `${profile?.lastName || ''}${profile?.firstName || ''}`;
    }

    const querystring = stringify({
      bookIds: resources,
      classType: classType,
      role: userType,
      userName: userName,
      userId: profile.id,
    },{ arrayFormat: 'index' });

    window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
  };

  // click 進入教室按鈕
  const onClickEnterClassHandler = () => {
    setState({ tableSessionId: id });
    enterInteractiveSession();
  };

  const openExam = (testId) => {
    const openUrl = window.open('', '_blank');
    const url = `${process.env.REACT_APP_TEST_DOMAIN}?project=${EXAM_PROJECT_NAME}&range=${testId}&user=${profile.id}&roomId=${id}&teacherName=${hostName}&username=${profile.nickname}`;
    openUrl.location = url;
  };

  const attendanceHandle = async () => {
    if(!isClick) return;
    setState({
      isClick:false
    });
    let checkType = 'checkIn';
    if(hostAttendance?.checkInAt) checkType = 'checkOut';
    await attendanceSession(id, timeSpanId, { [checkType]:true }, serverTimestamp);
    if(checkType === 'checkOut') window.open('https://docs.google.com/forms/d/e/1FAIpQLScqdg675ccYb0nHZ9LnvdgX1nVOK5iRuFrVZO7NVxyrplEuQg/viewform');
    setTimeout(()=>{
      setState({
        isClick:true
      });
    },1000);
  };

  const reviseVideoOnDemand = () => {
    window.open(`https://oneboard-bs-dev.oneclass.com.tw/player/${id}`);
  };

  const checkType = useMemo(()=>{
    if(sessionAttendanceManagementSetting !== ORGANIZATION_SETTINGS_VALUE.HOST || params.hostId !== profile.id){
      return {
        type:'notShow'
      };
    }

    if(hostAttendance?.checkOutAt){
      return {
        type:'finish',
        name:'簽退完成'
      };
    }else if(hostAttendance?.checkInAt){
      return {
        type:'needCheckOut',
        name:'教師簽退'
      };
    }else{
      if(type === 'end'){
        return {
          type:'finish',
          name:'觀看錄影'
        };
      }else{
        return {
          type:'needCheckIn',
          name:'教師簽到'
        };
      }

    }

  },[hostAttendance]);

  const goExamResultListPage = () => {
    history.push(`/home/${classId}/sessions/${id}/exam-result`);
  };

  // click 課堂報告
  const gotoSessionReport = () => {
    const location = {
      pathname: `/home/${classId}/sessions/${id}/timespan/${timeSpanId}/report`,
      state: {
        resourceId: resources[0],
        nowPage,
      }
    };
    history.push(location);
  };

  const enterGroup = (t) => {
    switch(t){
      case 'noStart':
        return (
          <Button disabled>課程尚未開始</Button>
        );
      case 'before30':
      case 'before10':
      case 'before5':
        return (
          <Button
            onClick={onClickEnterClassHandler}
            buttonColor='normal'
            dataGaCategory={getPageName(pathname)}
            dataGaAction='進入教室'
          >
            進入教室
          </Button>
        );
      // 上課時間結束後，5分鐘內
      case 'after5':
      case 'after90':
        return (
          <Button
            onClick={onClickEnterClassHandler}
            buttonColor='normal'
            dataGaCategory={getPageName(pathname)}
            dataGaAction='回到課堂'
          >
            回到課堂
          </Button>
        );
      case 'end':
        return (
          <Button disabled>課程結束</Button>
        );
      default:
        return null;
    }
  };

  return (
    <UiSessionsTableButtonBox>
      {enterGroup(type)}
      {
        (checkType.type !== 'notShow' && type !== 'noStart' && checkType.type !== 'finish') &&
        <OutlineButton
          buttonColor='normal'
          onClick={() => attendanceHandle()}
          loading={!isClick}
        >
          {checkType.name}
        </OutlineButton>
      }
      {
        // 此為 OneBroad 開課 並且 課程結束
        type === 'end' &&
        <>
          <Button
            buttonColor="new"
            onClick={()=> reviseVideoOnDemand()}
          >觀看錄影
          </Button>
        </>
      }
      <Button
        dataGaCategory={getPageName(pathname)}
        dataGaAction={userType === 'teacher' ? '課程教材' : ((type === 'after90' || type === 'end') ? '複習教材' : '預習教材')}
        // onClick={()=> onSessionModalToggle(id)}
        onClick={()=>setState({ isOpenResourceModal: true, tableSessionId: id, })}
      >
        {
          userType === 'teacher' ? '課程教材' : ((type === 'after90' || type === 'end') ? '複習教材' : '預習教材')
        }
      </Button>
      {
        (preExamId && userType === 'student') &&
        <Button
          buttonColor="visibility"
          dataGaCategory={getPageName(pathname)}
          dataGaAction='課前測驗'
          onClick={()=>openExam(preExamId)}
        >課前測驗
        </Button>
      }
      {
        (postExamId && userType === 'student') &&
        <Button
          buttonColor="visibility"
          dataGaCategory={getPageName(pathname)}
          dataGaAction='課後測驗'
          onClick={()=>openExam(postExamId)}
        >課後測驗
        </Button>
      }
      {
        ((preExamId || postExamId) && userType === 'teacher') &&
        <Button
          buttonColor="visibility"
          onClick={()=> goExamResultListPage()}
        >測驗結果
        </Button>
      }
      {
        // 課程類型為「協作、互動」，且「上課前十分鐘（預課時間）」，才顯示課堂報告
        ((sessionsType === 'collaborative' || sessionsType === 'interactive') && serverTimestamp > startAt - 10 * MINUTE) &&
          <Button
            onClick={gotoSessionReport}
          >課堂報告
          </Button>
      }
      {
        // 課程結束後，顯示「課後回饋」
        ((type === 'after5' || type === 'after90' || type === 'end') && userType === 'teacher') &&
          <Button
            onClick={() => setState({ isOpenFeedbackModal: true })}
          >
            課後回饋
          </Button>
      }
    </UiSessionsTableButtonBox>
  );
};

ActionComponents.propTypes = {
  params: PropTypes.object,
  isClick: PropTypes.bool,
  nowPage: PropTypes.number,
  sessionAttendanceManagementSetting: PropTypes.string,
  setState: PropTypes.func,
  serverTimestamp: PropTypes.number,
};